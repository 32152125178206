export enum StepModalLogin {
    INFO,
    SIGN_IN,
    SIGN_UP,
    FORGOR_PASSWORD
}

export enum StepSingUp {
    SIGN_UP,
    VERIFY_CODE
}

export enum StepForgotPassword {
    VERIFY_EMAIL,
    VERIFY_CODE,
    RESET_PASSWORD
}