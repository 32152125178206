// import { getHistory } from "@helpers/history";
import { PayloadAction } from '@reduxjs/toolkit'
import { call, delay, fork, take, put } from 'redux-saga/effects'
import { authActions, LoginPayload } from './authSlice'

function* handleLogin(payload: LoginPayload) {
    try {
        console.log('handle login')
        yield delay(1000)
        localStorage.setItem('access_token', 'fake_token')
        // yield put(authActions.loginSuccess({
        //     id: 1,
        //     name: 'daidv'
        // }));

        payload.navigate('/top')
    } catch (error) {
        console.log(error)
        yield put(authActions.loginFailed('error'))
        yield put(authActions.logout())
    }
}

function* handleLogout() {
    console.log('handle logout')
    localStorage.removeItem('access_token')
}

function* watchLoginFlow() {
    while (true) {
        const isLoggedIn = Boolean(localStorage.getItem('access_token'))
        if (!isLoggedIn) {
            const action: PayloadAction<LoginPayload> = yield take(
                authActions.login.type,
            )
            yield fork(handleLogin, action.payload)
        }

        yield take(authActions.logout.type)
        yield call(handleLogout)
    }
}

export default function* authSaga() {
    yield fork(watchLoginFlow)
}
