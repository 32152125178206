import { DialogContentProps } from '@radix-ui/react-dialog'
import { Dispatch, FC, SetStateAction } from 'react'
import { useWindow } from 'src/lib/context/WindowContext'
import { cn } from 'src/utils/common'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '../dialog'
import {
    Drawer,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerTitle,
    DrawerTrigger,
} from '../drawer'

export interface ShadModalProps extends DialogContentProps {
    children: React.ReactNode
    trigger?: React.ReactNode
    header?: React.ReactNode
    footer?: React.ReactNode
    isOpen?: boolean
    onOpen?: Dispatch<SetStateAction<boolean>>
    overlayClassName?: string
    hideClose?: boolean
}

const ShadModal: FC<ShadModalProps> = ({
    children,
    trigger,
    header,
    footer,
    isOpen,
    onOpen,
    className,
    overlayClassName,
    hideClose,
    ...props
}) => {
    const { isMobile } = useWindow()

    return (
        <>
            <div className={'block sm:hidden'}>
                <Drawer
                    open={isMobile && isOpen}
                    onOpenChange={isMobile ? onOpen : () => {}}
                >
                    <DrawerTrigger asChild>{trigger}</DrawerTrigger>
                    <DrawerContent
                        className={cn(
                            '!bg-[#10161F] border-[#344B6E] pb-10 !pt-4 !px-5 text-white min-h-[50%]',
                            className,
                        )}
                    >
                        {header && (
                            <DrawerHeader>
                                <DrawerTitle>{header}</DrawerTitle>
                            </DrawerHeader>
                        )}
                        <div className="h-full">{children}</div>
                        {footer && <DrawerFooter>{footer}</DrawerFooter>}
                    </DrawerContent>
                </Drawer>
            </div>
            <div className={'hidden sm:block'}>
                <Dialog
                    open={!isMobile && isOpen}
                    onOpenChange={!isMobile ? onOpen : () => {}}
                >
                    <DialogTrigger asChild>{trigger}</DialogTrigger>
                    <DialogContent
                        className={cn(
                            '!bg-[#10161F] border-[#344B6E] !px-5 text-white',
                            className,
                        )}
                        {...props}
                    >
                        {header && (
                            <DialogHeader>
                                <DialogTitle>{header}</DialogTitle>
                            </DialogHeader>
                        )}
                        {children}
                        {footer && <DialogFooter>{footer}</DialogFooter>}
                    </DialogContent>
                </Dialog>
            </div>
        </>
    )
}

export default ShadModal
