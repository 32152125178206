import { Button } from '@mui/material'
import copy from 'copy-to-clipboard'
import { CheckCircle, Copy, Settings } from 'lucide-react'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import EmptyState from 'src/components/Common/EmptyState'
import { Loading } from 'src/components/ui/Loading'
import CardNft from 'src/features/marketplace/components/CardNft'
import {
    NFTDetails,
    NftListingResponse,
} from 'src/features/marketplace/interfaces'
import { useAuth } from 'src/lib/context/AuthContext'
import { truncateAddress } from 'src/lib/wagmi/utils/truncateAddress'
import { cn } from 'src/utils/common'
import { fetcher } from 'src/utils/fetcher'
import useSWR from 'swr'
import { atom, useAtom } from 'jotai'
import InfiniteScroll from 'react-infinite-scroll-component'
import { genPramsCheckNull } from '../../../utils/genPramsCheckNull'
import {useBalance} from "wagmi";
import {formatNumberWithNumeral} from "../../../lib/wagmi/utils/formatNumber";
import {getContractByName} from "../../../lib/wagmi/configs/contractAddress";

enum TypeTab {
    OWNER_NFT,
    LISTED_NFT,
}

const Profile = () => {
    const { currentUser } = useAuth()
    const { connect_wallet_address, avatar, nick_name } =
        currentUser?.user ?? {}
    const navigator = useNavigate()
    const listTab = ['Owner NFTs', 'Listed NFTs']
    const [tab, setTab] = useState<TypeTab>(TypeTab.OWNER_NFT)
    const contractAddressArb = getContractByName("arb")
    const balanceEth = useBalance({
        address: currentUser?.user.connect_wallet_address as `0x${string }`,
    })

    // @ts-ignore
    const {data: balanceArb} = useBalance({
        address: currentUser?.user.connect_wallet_address  as `0x${string }`,
        token: contractAddressArb as `0x${string }`
    })


    const copyToClipboardWithCommand = () => {
        copy(connect_wallet_address as string, { format: 'text/plain' })
        toast.success('Copied')
    }



    const listBalance = [{
        balance: balanceEth?.data?  balanceEth?.data.formatted : 0,
        name: 'ETH',
        img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png'
    }, {
        balance: balanceArb?  balanceArb?.formatted : 0,
        name: 'ARB',
        img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11841.png'
    },{
        balance: currentUser?.user?.snack_balance?   currentUser?.user?.snack_balance : 0,
        name: 'SNACK',
        img:  require('../../../assets/images/web/cat-face.png')
    }]

    return (
        <div>
            <div className="bg-slate-800 rounded-lg p-6">
               <div className={"flex flex-wrap justify-between"}>
                  <div className={"flex mb-2"}>
                      <img
                          src={avatar}
                          alt="logo profile"
                          className="w-[80px] h-[80px] rounded-full mr-4 xs:mr-6"
                      />
                      <div>
                          <p className=" font-medium text-white  text-2xl">
                              {nick_name}
                          </p>

                          <div className="flex items-center mt-2">
                              {connect_wallet_address ? (
                                  <div className="justify-between items-center flex bg-[#16202D] rounded-[10px] max-w-[280px] w-full px-3 py-2">
                                      <div className="flex items-center">
                                          <Copy
                                              onClick={copyToClipboardWithCommand}
                                              className="text-[#416697] w-4 h-4 cursor-pointer"
                                          />
                                          <p className=" text-sm text-[#416697] font-normal px-3">
                                              {truncateAddress(
                                                  connect_wallet_address,
                                              )}
                                          </p>
                                      </div>
                                      <CheckCircle className="w-4 h-4 text-[#42DC6E]" />
                                  </div>
                              ) :  <Button
                                  onClick={() => navigator('/profile/setting')}
                                  className="main-button !h-[30px] !rounded !text-sm"
                                  >
                                  Connect Wallet
                                  </Button>}
                          </div>
                      </div>
                  </div>
                   <div
                       onClick={() => navigator('/profile/setting')}
                       className={"bg-slate-600 h-fit rounded-lg px-3 py-1 items-center flex w-fit cursor-pointer "}>
                       <Settings className="text-[#899DCD] w-5 h-5 mr-2" />
                        <p className={"text-slate-200"}>
                            Customize Profile
                        </p>
                   </div>
               </div>
                <div className={"mt-4 flex flex-wrap"}>
                    {
                        listBalance?.map((e, idx) => {
                           return (
                               <div className={"flex mr-4 w-fit py-1 items-center px-2 rounded-lg bg-slate-600"} key={`item-balance-${idx}`}>
                                   <p className={"text-slate-200  mr-1 font-medium text-base"}> {formatNumberWithNumeral(e?.balance, 6)}</p>
                                    <img src={e?.img} className={"w-6 h-6"} alt={`logo-${e?.name}`}/>
                               </div>
                           )
                        })
                    }
                </div>
            </div>
            <div className="flex mt-20">
                {listTab?.map((e, idx) => {
                    return (
                        <Button
                            onClick={() => setTab(idx)}
                            className={cn(
                                '!bg-transparent text-sm xs:!text-base !text-[#959595]',
                                idx === tab && 'main-button-green',
                                idx && '!ml-6 xs:!ml-8',
                            )}
                            key={e}
                        >
                            {e}
                        </Button>
                    )
                })}
            </div>
            <div className="w-full flex flex-col py-6">
                {tab === TypeTab.OWNER_NFT && <ListNftOwner />}

                {tab === TypeTab.LISTED_NFT && <ListNftOwnerListing />}
            </div>
        </div>
    )
}

const listNftOwnerListingAtom = atom<NftListingResponse[]>([])
const listNftOwnerAtom = atom<NFTDetails[]>([])

const ListNftOwner = () => {
    const [page, setPage] = useState(1)
    const { currentUser } = useAuth()
    const [dataListNftOwner, setDataListNftOwner] = useAtom(listNftOwnerAtom)

    const userId = currentUser?.user?.id
    const apiUrl = userId
        ? `${process.env.REACT_APP_API}/v1/marketplace/nft-owned/${userId}?limit=20&page=${page}`
        : null

    const { data: listNftOwner, isLoading: isLoadingOwnerListing } = useSWR(
        apiUrl,
        fetcher,
    )

    useEffect(() => {
        if (listNftOwner?.data) {
            const newItems = listNftOwner.data.filter(
                (newItem) =>
                    !dataListNftOwner.some(
                        (existingItem) =>
                            existingItem.nft_id === newItem.nft_id,
                    ),
            )
            if (newItems.length > 0) {
                setDataListNftOwner((prevData) => [...prevData, ...newItems])
            }
        }
    }, [listNftOwner?.data])

    return (
        <div>
            {!dataListNftOwner && isLoadingOwnerListing ? (
                <Loading />
            ) : dataListNftOwner && dataListNftOwner?.length > 0 ? (
                <InfiniteScroll
                    dataLength={dataListNftOwner.length}
                    next={() => setPage(page + 1)}
                    hasMore={listNftOwner?.data?.length === 20}
                    loader={<Loading />}
                    endMessage={
                        <p style={{ textAlign: 'center' }} className="mt-3">
                            <b className="text-white">
                                {' '}
                                Yay! You have seen it all
                            </b>
                        </p>
                    }
                >
                    <div className="w-full flex  sm:h-full style-scroll-hidden overflow-auto  flex-wrap gap-4 justify-center md:!justify-normal">
                        {dataListNftOwner.map((item, index) => {
                            if (item.nft_id) {
                                return (
                                    <CardNft
                                        dataOwner={item}
                                        key={`card-nft-profile-${index}`}
                                    />
                                )
                            }
                        })}
                    </div>
                </InfiniteScroll>
            ) : (
                <EmptyState />
            )}
        </div>
    )
}

const ListNftOwnerListing = () => {
    const { currentUser } = useAuth()
    const [page, setPage] = useState(1)
    const [listNftOwnerListingData, setListNftOwnerListingData] = useAtom(
        listNftOwnerListingAtom,
    )

    const { data: listNftOwnerListing, isLoading: isLoadingOwnerListing } =
        useSWR(
            genPramsCheckNull(
                {
                    page: page,
                    limit: 20,
                    'user-id': currentUser?.user?.id,
                },
                `${process.env.REACT_APP_API}/v1/marketplace`,
            ),
            fetcher,
        )

    useEffect(() => {
        if (listNftOwnerListing?.data) {
            const newItems = listNftOwnerListing.data.filter(
                (newItem) =>
                    !listNftOwnerListingData.some(
                        (existingItem) =>
                            existingItem.nft.nft_id === newItem.nft.nft_id,
                    ),
            )
            if (newItems.length > 0) {
                setListNftOwnerListingData((prevData) => [
                    ...prevData,
                    ...newItems,
                ])
            }
        }
    }, [listNftOwnerListing?.data])

    return (
        <div className={'w-full flex flex-col'}>
            {!listNftOwnerListingData && isLoadingOwnerListing ? (
                <Loading />
            ) : listNftOwnerListingData?.length > 0 ? (
                <InfiniteScroll
                    dataLength={listNftOwnerListingData.length}
                    next={() => setPage(page + 1)}
                    hasMore={listNftOwnerListing?.data?.length === 20}
                    loader={<Loading />}
                    endMessage={
                        <p style={{ textAlign: 'center' }} className="mt-3">
                            <b className="text-white">
                                {' '}
                                Yay! You have seen it all
                            </b>
                        </p>
                    }
                >
                    <div className="w-full flex flex-wrap justify-center style-scroll-hidden md:justify-normal gap-4">
                        {listNftOwnerListingData.map((item) => (
                            <CardNft
                                item={item}
                                key={`card-nft-profile-listing-x-${item.nft.nft_id}`}
                            />
                        ))}
                    </div>
                </InfiniteScroll>
            ) : (
                <EmptyState />
            )}
        </div>
    )
}

export default Profile
