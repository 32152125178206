import * as Tooltip from '@radix-ui/react-tooltip';
import { useState } from 'react';
import {cn} from "../../utils";
import {preventBubbling} from "../../utils/preventBubbling";

interface TooltipShadProps {
    trigger: React.ReactNode;
    content: React.ReactNode;
    classContent?: string;
    side?: 'top' | 'right' | 'bottom' | 'left';
    onOpen?: () => void;
}

const TooltipShad = ({ trigger, content, side, classContent, onOpen }: TooltipShadProps) => {
    const [isOpenTooltip, setIsOpenTooltip] = useState(false);
    return (
        <Tooltip.Provider delayDuration={0}>
            <Tooltip.Root
                onOpenChange={e => {
                    if (onOpen && e) onOpen();
                    setIsOpenTooltip(e);
                }}
                open={isOpenTooltip}>
                <Tooltip.Trigger onClick={preventBubbling(() => setIsOpenTooltip(true))} className="cursor-help" asChild>
                    {trigger}
                </Tooltip.Trigger>
                <Tooltip.Portal>
                    <Tooltip.Content
                        side={side ? side : 'bottom'}
                        className={cn('p-2 rounded-lg bg-slate-800 text-slate-400 text-sm', classContent ? classContent : '')}
                        sideOffset={5}
                        style={{
                            position: 'relative',
                            zIndex: 10000,
                        }}>
                        {content}
                        <Tooltip.Arrow className="TooltipArrow fill-slate-800" />
                    </Tooltip.Content>
                </Tooltip.Portal>
            </Tooltip.Root>
        </Tooltip.Provider>
    );
};

export default TooltipShad;
