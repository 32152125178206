import BigNumber from 'bignumber.js'
import numeral from 'numeral'
export const shrinkDecimals = (
    amount: string | number,
    decimal: number,
): string => {
    if (!amount) {
        return '0'
    }
    const divider = new BigNumber(10).pow(decimal)
    return new BigNumber(amount || 0).div(divider).toFixed()
}

export const formatNumberWithNumeral = (
    val: number | string,
    suffix?: number,
): string => {
    const stringDefault = '0000000000'
    try {
        const num = new BigNumber(val)

        if (Number(val) === 0) {
            return '0'
        }
        if (Number.isInteger(Number(val))) {
            return val?.toString()
        }

        if (val.toString())
            if (num.isLessThan(1))
                return num.toFixed(suffix || 3, BigNumber.ROUND_DOWN)
            else if (num.isLessThan(1000)) {
                const newData = num.toFixed(suffix || 4, BigNumber.ROUND_DOWN)
                return numeral(newData).format(
                    `0,0.${stringDefault.slice(0, suffix ?? 4)}`,
                )
            } else if (num.isLessThan(1000000)) {
                const newData = num.toFixed(
                    Number(Number(suffix) < 2 ? suffix : 2),
                    BigNumber.ROUND_DOWN,
                )
                return numeral(newData).format('0,0.00')
            } else {
                const newData = num.toFixed(
                    Number(Number(suffix) < 2 ? suffix : 2),
                    BigNumber.ROUND_DOWN,
                )
                return numeral(newData).format('0,0')
            }
        else return '0.000'
    } catch (e) {
        return '0.000'
    }
}
