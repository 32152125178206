import { Button, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import CustomSlider from 'src/components/Common/CustomSlider'
import { Drawer, DrawerContent, DrawerTrigger } from 'src/components/ui/drawer'
import { Sheet, SheetContent, SheetTrigger } from 'src/components/ui/sheet'
import { useWindow } from 'src/lib/context/WindowContext'
import { useModal } from 'src/lib/hooks/useModal'
import { cn } from 'src/utils/common'

interface ModalFilterProps {
    filterClass: number
    setFilterClass: (val: number) => void
    setFilterRarity: (val: number) => void
    setFilterLevel: (val: number) => void
    filterRarity: number
    filterLevel: number
    setFilterPrice: (val: { minPrice: string; maxPrice: string }) => void
    filterPrice: { minPrice: string; maxPrice: string }
    onReset: () => void
}

const ModalFilter = ({
    filterClass,
    onReset,
    filterLevel,
    filterRarity,
    filterPrice,
    setFilterPrice,
    setFilterClass,
    setFilterLevel,
    setFilterRarity,
}: ModalFilterProps) => {
    const { open, openModal, setOpen, closeModal } = useModal()
    const listFilterGenre = ['Cat', 'Dog', 'Rabbit', 'Bear', 'Dragon']
    const { isMobile } = useWindow()

    return (
        <div>
            <Sheet open={open} onOpenChange={setOpen}>
                <SheetTrigger asChild>
                    <div
                        className="shrink-0 cursor-pointer rounded-lg p-2 text-sm font-medium !text-gray-500 hover:!bg-gray-50 hover:!text-gray-700"
                        // onClick={toggleDrawer(true)}
                    >
                        <p>Filter</p>
                    </div>
                </SheetTrigger>
                <SheetContent
                    side={isMobile ? 'bottom' : 'right'}
                    className={cn(
                        '!bg-[#070A0E] border-none  pb-10 !px-4  text-white ',
                    )}
                >
                    <div className="xs:h-full h-[80vh] overflow-auto style-scroll-hidden">
                        <div className={cn('h-full ', isMobile && '!w-full')}>
                            <p className="text-center mt-10 text-3xl text-white">
                                Filter
                            </p>

                            <p className="text-white mb-3 text-base font-medium">
                                Genre
                            </p>
                            <div className="flex flex-wrap">
                                {listFilterGenre.map((e, idx) => {
                                    const isActive = filterClass === idx + 1
                                    return (
                                        <div
                                            onClick={() =>
                                                setFilterClass(idx + 1)
                                            }
                                            className={cn(
                                                'py-1 mr-4 px-[18px] mb-4 cursor-pointer font-normal text-white bg-[#1A283A] rounded-[5px] border border-[#1A283A]',
                                                isActive && 'border-[#ec713a]',
                                            )}
                                        >
                                            {e}
                                        </div>
                                    )
                                })}
                            </div>

                            <div className="my-6 mr-10">
                                <p className="text-white mb-3 text-base font-medium">
                                    Rarity
                                </p>
                                <CustomSlider
                                    aria-labelledby="custom-slider"
                                    step={1}
                                    marks
                                    onChange={(e: any) => {
                                        setFilterRarity(e?.target?.value)
                                    }}
                                    value={filterRarity}
                                    className=" shadow-none ml-3"
                                    max={5}
                                />
                            </div>

                            <div className="mr-10">
                                <p
                                    className={cn(
                                        'text-white mb-3 text-base font-medium',
                                    )}
                                >
                                    Level
                                </p>
                                <CustomSlider
                                    defaultValue={1}
                                    aria-labelledby="custom-slider"
                                    step={1}
                                    marks
                                    onChange={(e: any) => {
                                        setFilterLevel(e?.target?.value)
                                    }}
                                    value={filterLevel}
                                    className=" shadow-none ml-3"
                                    max={5}
                                />
                            </div>

                            <p className="text-white mt-6 text-base font-medium">
                                Price (#ANI)
                            </p>
                            <div className="flex w-full items-center mb-12 mt-3 justify-between">
                                <input
                                    value={filterPrice?.minPrice}
                                    onChange={(e) => {
                                        setFilterPrice({
                                            maxPrice: filterPrice?.maxPrice,
                                            minPrice: e.target.value,
                                        })
                                    }}
                                    className="bg-[#1A283A] text-xl text-white font-bold border-none focus:outline-none rounded-[10px] h-[53px] max-w-[165px] w-full"
                                />

                                <div className="h-[2px] w-[10px] rounded-[30px] bg-[#1A283A]" />

                                <input
                                    value={filterPrice?.maxPrice}
                                    onChange={(e) => {
                                        setFilterPrice({
                                            minPrice: filterPrice?.minPrice,
                                            maxPrice: e.target.value,
                                        })
                                    }}
                                    className="bg-[#1A283A] text-xl text-white font-bold border-none focus:outline-none rounded-[10px] h-[53px] max-w-[165px] w-full"
                                />
                            </div>

                            <Button
                                onClick={onReset}
                                className="main-button !h-[53px] !w-full"
                            >
                                RESET
                            </Button>
                        </div>
                    </div>
                </SheetContent>
            </Sheet>
        </div>
    )
}

export default ModalFilter
