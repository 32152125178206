import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {createWeb3Modal} from '@web3modal/wagmi/react'
import {Route, Routes} from 'react-router-dom'
import {WagmiProvider} from 'wagmi'
import './App.css'
import {NotFound} from './components/Common'
import CodeOfConduct from './features/codeOfConduct/CodeOfConduct'
import LandingPage from './features/landing/pages/LandingPage'
import HomePage from './features/marketplace/pages/HomePage'
import MarketplaceDetailPage from './features/marketplace/pages/MarketplaceDetailPage'
import NewDetail from './features/marketplace/pages/NewDetailPage'
import News from './features/marketplace/pages/NewsPage'
import ProfilePage, {
    SettingProfilePage,
} from './features/marketplace/pages/ProfilePage'
import PostDetail from './features/post/pages/detail'
import PrivacyPolicy from './features/privacyPolicy/PrivacyPolicy'
import TermsOfUse from './features/termsOfUse/TermsOfUse'
import {AuthProvider} from './lib/context/AuthContext'
import {config, projectId} from './lib/wagmi/connectors'
import {Toaster} from 'react-hot-toast'
import ListMarketplacePage from './features/marketplace/pages/ListMarketplacePage'
import MarketplaceLayout from './components/Layout/Marketplace'
import HomeMarketPlace from './features/marketplace/pages/HomeMarketPlace'
import {WindowContextProvider} from './lib/context/WindowContext'
import {ModalProvider} from './lib/context/ModalContext'
import 'react-toastify/dist/ReactToastify.css';

import WOW from 'wowjs'
import 'wowjs/css/libs/animate.css'
import 'animate.css'
import React, {useEffect} from 'react'
import {ToastContainer} from "react-toastify";
import {useFetchPriceEth} from "./lib/hooks/usePriceEth";
import StakingPage from "./features/marketplace/pages/Staking";

const GlobalHook = () => {
    useFetchPriceEth()
    return null
}

const queryClient = new QueryClient()

    const Web3Hook = () => {

        createWeb3Modal({
            wagmiConfig: config,
            projectId,
        })
        return null
    }
function App() {
    useEffect(() => {
        const wow = new WOW.WOW()
        wow.init()
    }, [])

    return (
        <div>
            <GlobalHook/>
            <Web3Hook/>
            <QueryClientProvider client={queryClient}>
                <WagmiProvider config={config}>
                    <ProviderApp/>
                </WagmiProvider>
            </QueryClientProvider>
        </div>
    )
}


const ProviderApp = () => {
    return (
        <>
            <AuthProvider>
                <WindowContextProvider>
                    <ModalProvider>


                            <Routes>
                                <Route element={<MarketplaceLayout/>}>
                                    <Route
                                        path="/"
                                        element={<HomePage/>}
                                    />
                                    <Route
                                        path="/marketplace"
                                        element={<HomeMarketPlace/>}
                                    />
                                    <Route
                                        path="/marketplace/:category"
                                        element={<ListMarketplacePage/>}
                                    />
                                    <Route
                                        path="/marketplace/:category/:id"
                                        element={<MarketplaceDetailPage/>}
                                    />
                                    <Route
                                        path="/profile"
                                        element={<ProfilePage/>}
                                    />
                                    <Route
                                        path="/staking"
                                        element={<StakingPage/>}
                                    />
                                    <Route
                                        path="/profile/setting"
                                        element={<SettingProfilePage/>}
                                    />
                                    <Route
                                        path="/news"
                                        element={<News/>}
                                    />
                                    <Route
                                        path="/news/:id"
                                        element={<NewDetail/>}
                                    />
                                </Route>

                                <Route
                                    path="post-detail/news/:id"
                                    element={<PostDetail/>}
                                />

                                <Route path="*" element={<NotFound/>}/>
                            </Routes>
                    </ModalProvider>
                </WindowContextProvider>
            </AuthProvider>
            <Toaster
                position="top-center"
                reverseOrder={false}
                toastOptions={{
                    style: {
                        color: '#5A6E7D',
                        borderRadius: '4px',
                        padding: '8px 16px',
                        minWidth: '200px',
                        backgroundColor: '#ffff',
                    },
                    success: {duration: 1200},
                }}
                containerClassName="mb-12 xs:mb-0 z-10 px-4 py-2  "
            />
            <ToastContainer/>
        </>
    )
}

export default App
