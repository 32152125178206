import {useCallback, useEffect, useState} from 'react'
import useSWR from 'swr'
import {fetcher} from '../../../utils/fetcher'
import {get, post} from '../../../api/axiosClient'
import {Input} from '../../../components/ui/input'
import {Button} from '../../../components/ui/button'
import {StepSingUp} from '../interfaces'
import {useAuth} from '../../../lib/context/AuthContext'
import toast from 'react-hot-toast'
import {Loading} from "../../../components/ui/Loading";

const StepSignUp = ({closeModal}: { closeModal: () => void }) => {
    const [step, setStep] = useState(StepSingUp.SIGN_UP)
    const [dataCaptcha, setDataCaptcha] = useState<any>()
    const [isLoading, setIsLoading] = useState(false)
   const [isLoadingCaptcha, setIsLoadingCaptcha] = useState(false)
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        nickname: '',
        captcha: '',
    })
    const [errors, setErrors] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        nickname: '',
        captcha: '',
    })

    const handleGetCaptcha = async () => {
        try {
            setIsLoadingCaptcha(true)
            const response = await get(`${process.env.REACT_APP_API_AUTH}/captcha/api/flat`).then((res)=> res)
            console.log("response",response)
            setDataCaptcha(response)
        } catch (error) {
            console.error('Error getting captcha', error)
        }finally {
            setIsLoadingCaptcha(false)
        }
    }

    useEffect(() => {
        handleGetCaptcha()
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target
        setFormData({...formData, [name]: value})
    }

    const validate = (values: any) => {
        const errors: any = {}

        if (!values.email || !/^\S+@\S+\.\S+$/.test(values.email)) {
            errors.email = 'Please enter a valid email address.'
        }

        if (!values.password) {
            errors.password = 'Please enter a password.'
        } else if (values.password.length < 8) {
            errors.password = 'Password must be at least 8 characters long.'
        }

        if (!values.confirmPassword) {
            errors.confirmPassword = 'Please confirm your password.'
        } else if (values.password !== values.confirmPassword) {
            errors.confirmPassword = 'Passwords do not match.'
        }

        if (!values.nickname) {
            errors.nickname = 'Please enter a nickname.'
        }

        if (!values.captcha) {
            errors.captcha = 'Please enter the captcha.'
        }

        return errors
    }


    const hanldeSignUp = useCallback(
        async (e) => {
            e.preventDefault()

            const newErrors = validate(formData)
            if (Object.keys(newErrors).length !== 0) {
                setErrors(newErrors)
                return
            }

            try {
                setIsLoading(true)
                const response = await post(
                    `${process.env.REACT_APP_API_AUTH}/api/register`,
                    {
                        email: formData?.email,
                        password: formData?.password,
                        nick_name: formData?.nickname,
                        captcha: formData?.captcha,
                        password_confirmation: formData?.confirmPassword,
                        key: dataCaptcha.key,
                    },
                )

                setStep(StepSingUp.VERIFY_CODE)
            } catch (error: any) {
                console.error('Error signing up', error)
            } finally {
                setIsLoading(false)
            }
        },
        [formData, dataCaptcha?.key],
    )

    return (
        <div>
            {step === StepSingUp.SIGN_UP ? (
                <form onSubmit={hanldeSignUp}>
                    <p className={'text-3xl mb-6 font-semibold text-white '}>
                        Register
                    </p>

                    <Input
                        value={formData.nickname}
                        name="nickname"
                        placeholder="Enter nickname"
                        className="w-full bg-[#0d0e12] rounded-md text-white"
                        onChange={handleChange}
                        error={errors.nickname}
                    />
                    <Input
                        placeholder="Enter email"
                        value={formData.email}
                        name="email"
                        className="w-full bg-[#0d0e12] mt-4 rounded-md text-white"
                        onChange={handleChange}
                        error={errors.email}
                    />

                    <Input
                        placeholder="Enter password"
                        type="password"
                        value={formData.password}
                        name="password"
                        className="mt-4 w-full main border bg-[#0d0e12] text-white rounded-md"
                        onChange={handleChange}
                        error={errors.password}
                    />

                    <Input
                        placeholder="Enter confirm password"
                        type="password"
                        value={formData.confirmPassword}
                        name="confirmPassword"
                        className="mt-4 w-full main border bg-[#0d0e12] text-white rounded-md"
                        onChange={handleChange}
                        error={errors.confirmPassword}
                    />

                    <Input
                        value={formData.captcha}
                        name="captcha"
                        placeholder="Enter Captcha"
                        className="w-full main border mt-4 bg-[#0d0e12] text-white rounded-md"
                        onChange={handleChange}
                        error={errors.captcha}
                    />

                    {isLoadingCaptcha&& !dataCaptcha ?
                        <Loading/>
                        :  !!dataCaptcha ? (
                        <div>

                            <img
                                src={dataCaptcha?.img}
                                alt="Captcha"
                                className={'h-10 mt-4'}
                            />
                            <p className={"text-sm mt-2 text-white"}>
                                Wants new captcha code ? <span
                                onClick={handleGetCaptcha}
                                className={"text-[#F58E3F] hover:text-[#a04906] cursor-pointer"}>
                            Refresh
                    </span>
                            </p>
                        </div>
                    ) : null}

                    <Button
                        loading={isLoading}
                        type={'submit'}
                        className={'w-full mt-6'}
                        variant={'main'}
                    >
                        { !isLoading && 'Continue'}
                    </Button>
                </form>
            ) : (
                <StepVerifyCodeRegister
                    closeModal={closeModal}
                    email={formData?.email}
                />
            )}
        </div>
    )
}

const StepVerifyCodeRegister = ({
                                    email,
                                    closeModal,
                                }: {
    email: string
    closeModal: () => void
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [code, setCode] = useState('')
    const [errorVerifyCode, setErrorVerifyCode] = useState('')

    const {setCurrentUser} = useAuth()

    const handleVerifyCode = useCallback(
        async (e) => {
            e.preventDefault()

            try {
                setIsLoading(true)
                const response = await post(
                    `${process.env.REACT_APP_API_AUTH}/api/register/verify-code`,
                    {
                        email: email,
                        code: code,
                    },
                    {}, true,
                    setErrorVerifyCode
                ).then((res) => res)
                setCurrentUser(response)
                setErrorVerifyCode("")
                closeModal()
            } catch (error: any) {
                console.error('Error verify code register', error)
            } finally {
                setIsLoading(false)
            }
        },
        [code, email],
    )

    const handleResendCode = useCallback(async () => {
        try {
            setIsLoading(true)
            await post(
                `${process.env.REACT_APP_API_AUTH}/api/register/resend-code`,
                {
                    email: email,
                },
            )

        } catch (error) {
            console.error('Error Resend code verify email', error)
        } finally {
            setIsLoading(false)
        }
    }, [])

    return (
        <div>
            <p className={'text-3xl font-semibold mb-2 text-white'}>
                Verify Code
            </p>

            <p className={'font-semibold mb-8 text-[#F58E3F]'}>
                A verification code has been sent to your email. Please check your email to complete the registration.
            </p>

            <form onSubmit={handleVerifyCode}>
                <Input
                    value={code}
                    placeholder="Enter Code"
                    className="mt-4 w-full main border bg-[#0d0e12] text-white rounded-md"
                    onChange={(e) => {
                        if(errorVerifyCode) {
                            setErrorVerifyCode("")
                        }
                        setCode(e.target.value)
                    } }
                    error={errorVerifyCode}
                />
                <Button loading={isLoading} disabled={code?.trim() === "" || !!errorVerifyCode} type={'submit'} className={'w-full mt-6'}>
                    { !isLoading && 'Verify' }
                </Button>

                <div className={'w-full flex justify-center'}>
                    <span className={'text-sm text-white text-center mt-5'}>
                        Didn't receive a code?
                        <span
                            onClick={handleResendCode}
                            className={
                                'pl-1 text-[#F58E3F] hover:text-[#a04906] cursor-pointer '
                            }
                        >
                            Resend
                        </span>
                    </span>
                </div>
            </form>
        </div>
    )
}

export default StepSignUp
