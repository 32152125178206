import React, {useEffect, useState} from 'react'
import {fetcher} from 'src/utils/fetcher'
import {genPramsCheckNull} from 'src/utils/genPramsCheckNull'
import useSWR from 'swr'
import {NftListingResponse} from '../interfaces'
import {cn} from 'src/utils/common'
import ComingSoon from 'src/components/Common/ComingSoon'
import EmptyState from 'src/components/Common/EmptyState'
import InfiniteScroll from 'react-infinite-scroll-component'
import {Loading} from '../../../components/ui/Loading'
import NewlyListed from "./NewlyListed";
import NewlyAcquired from "./NewlyAcquired";

type Props = {}
enum TabNFTItemStats {
    NEW_LISTING,
    NEW_ACQUIRED,
    NOTI,
}

const NFTItemStats = (props: Props) => {
    const [page, setPage] = useState(1)
    const [tabIndex, setTabIndex] = useState(TabNFTItemStats.NEW_LISTING)
    const [listNftListingData, setListNftListingData] = useState<
        NftListingResponse[]
    >([])

    const { data } = useSWR(
        genPramsCheckNull(
            {
                'order-type': 'desc',
                page: page,
                limit: 10,
                'sort-by': 'created_at',
                status: 'listing',
            },
            `${process.env.REACT_APP_API}/v1/marketplace`,
        ),
        fetcher,
    )
    useEffect(() => {
        if (
            data?.data &&
            JSON.stringify(data?.data) !== JSON.stringify(listNftListingData)
        ) {
            setListNftListingData((prevData) => [...prevData, ...data?.data])
        }
    }, [data?.data])



    return (
        <div className="bg-[#0D1219] rounded-[20px]">
            <nav
                className="flex items-center style-scroll-hidden overflow-auto gap-2 sm:gap-6 bg-[#16202D] h-[56px] rounded-t-[20px] text-base sm:text-xl"
                aria-label="Tabs"
            >
                {['Newly Listed', 'Newly Acquired' ,'Notifications'].map((e, idx) => {
                    return (
                        <p
                            onClick={() => setTabIndex(idx)}
                            key={`item-tab-${e}`}
                            className={cn(
                                'shrink-0 px-5 cursor-pointer font-medium text-white h-full flex items-center',
                                idx === tabIndex &&
                                    'bg-gradient-to-r from-[#688F3E] to-[#4C692F]',
                            )}
                        >
                            {e}
                        </p>
                    )
                })}
            </nav>
            {tabIndex === TabNFTItemStats.NOTI && <ComingSoon />}
            {tabIndex === TabNFTItemStats.NEW_ACQUIRED && <NewlyAcquired />}
            {tabIndex === TabNFTItemStats.NEW_LISTING && (
                <>
                    {listNftListingData && listNftListingData.length > 0 ? (
                        <InfiniteScroll
                            next={() => setPage(page + 1)}
                            hasMore={data?.data?.length === 10} // Ensure this logic correctly reflects when there is no more data to fetch
                            scrollThreshold={0.9}
                            loader={<Loading />}
                            dataLength={listNftListingData.length}
                            endMessage={
                                <p
                                    style={{ textAlign: 'center' }}
                                    className="mt-3"
                                >
                                    <b className="text-white">
                                        {' '}
                                        Yay! You have seen it all
                                    </b>
                                </p>
                            }
                        >
                           <NewlyListed listNftListingData={listNftListingData} setListNftListingData={setListNftListingData} />
                        </InfiniteScroll>
                    ) : (
                        <EmptyState />
                    )}
                </>
            )}
        </div>
    )
}

export default NFTItemStats
