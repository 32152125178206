import React, {useCallback, useEffect, useState} from "react";
import {useAuth} from "../../../lib/context/AuthContext";
import {Input} from "../../../components/ui/input";
import {Button} from "../../../components/ui/button";
import {StepModalLogin} from "../interfaces";
import {getDataFromCookie, setDataToCookie, deleteCookie} from "../../../lib/cookie/client";

const StepLogin = ({setStepModal}: { setStepModal: (val: StepModalLogin) => void }) => {
    const dataLoginCache = getDataFromCookie("remember_me") as {
        email: string,
        password: string
    }
    const [email, setEmail] = useState(dataLoginCache?.email)
    const [password, setPassword] = useState(dataLoginCache?.password)
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const {login, loadingLogin} = useAuth()
    const [isChecked, setIsChecked] = useState(true)

    const handleActionRememberMe = () => {
        if(isChecked){
            setDataToCookie('remember_me', {
                email,
                password
            })
        } else { 
            deleteCookie('remember_me')
        }
    }

    const handleSigIn = useCallback(async () => {
        // Clear previous errors
        setEmailError('');
        setPasswordError('');

        // Validate email
        if (!email || !/^\S+@\S+\.\S+$/.test(email)) {
            setEmailError('Please enter a valid email address.');
            return;
        }

        // Validate password
        if (!password) {
            setPasswordError('Please enter your password.');
            return;
        }

        // Proceed with sign-in
        login(email, password, () => handleActionRememberMe());
    }, [login, email, password, handleActionRememberMe])

    return (
        <div className="my-auto h-fit">

            <p className="text-white text-2xl pt-10 font-semibold pb-8">
                Log in with Email & Password
            </p>

            <Input
                value={email}
                placeholder="Email"
                className="w-full bg-[#0d0e12] rounded-md text-white"
                onChange={(e) => setEmail(e.target.value)}
                error={emailError}
            />
            <Input
                value={password}
                placeholder="Password"
                type="password"
                className="mt-4 w-full main border bg-[#0d0e12] text-white rounded-md"
                error={passwordError}
                onChange={(e) => setPassword(e.target.value)}
            />
            <div className={"w-full mt-4  items-center flex justify-between"}>
              <div className={"flex items-center"}>
                  <input
                      checked={isChecked}
                      onChange={(e) => {
                          setIsChecked( e?.target?.checked)
                      }}
                      className={"w-5 h-5 rounded border-none shadow-none focus:outline-none text-[#F58E3F] hover:text-[#a04906]"} type={"checkbox"} />
                  <p className={"pl-2 text-sm font-normal text-[#737d8f]"}>
                      Remember me
                  </p>
              </div>
                <p
                    onClick={() => setStepModal(StepModalLogin.FORGOR_PASSWORD)}
                    className={"text-sm text-right text-[#F58E3F] hover:text-[#a04906] cursor-pointer"}>
                    Forgot password?
                </p>
            </div>

            <Button
                loading={loadingLogin}
                onClick={handleSigIn}
                className="main-button w-full !mt-6"
            >
                { !loadingLogin && 'Sign in' }
            </Button>
        </div>
    )
}

export default StepLogin