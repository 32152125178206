/* eslint-disable no-sparse-arrays */
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import ModalViewTransactionHash from 'src/components/Common/ModalViewTransactionHash'
import { useAuth } from 'src/lib/context/AuthContext'
import { useModalContext } from 'src/lib/context/ModalContext'
import NftMarketPlaceAbi from 'src/lib/wagmi/configs/abi/MaketPlaceAbi.json'
import NftAbi from 'src/lib/wagmi/configs/abi/NftAbi.json'

import { getContractByName } from 'src/lib/wagmi/configs/contractAddress'
import { fetcher } from 'src/utils/fetcher'
import { formatEther, parseEther } from 'viem'
import { BaseError, useAccount, useReadContract, useWriteContract } from 'wagmi'

export const useBuyNft = (listingIndex: number, onDoneBuy?: () => void) => {
    const { currentUser } = useAuth()
    const { openModal, closeModal } = useModalContext()
    const marketplaceContract = getContractByName('marketplace')
    const { writeContract, isError, isPending, error, isSuccess, data } =
        useWriteContract()
    const handleBuyNft = async (paymentAmount: string | number) => {
        try {
            const valueBigInt = BigInt(paymentAmount.toString())
            const data = await fetcher(
                `${process?.env?.REACT_APP_API}/v1/marketplace/buy-listing?listing-index=${listingIndex}&buyer-address=${currentUser?.user?.connect_wallet_address}`,
            )
             writeContract({
                abi: NftMarketPlaceAbi,
                address: marketplaceContract as `0x${string}`,
                functionName: 'buy',
                args: [listingIndex, data?.data?.signature],
                value: valueBigInt,
            })
        } catch (error) {
            toast.error((error as any)?.message)
            console.error('error: buy nft :>> ', error)
        }
    }

    useEffect(() => {
        if (isError && error) {
            const errorToast =
                (error as BaseError).shortMessage || error.message
            console.error('error buy nft :>> ', error)
            toast.error(errorToast)
        }
        if (isSuccess) {
            openModal(
                <ModalViewTransactionHash
                    closeModal={closeModal}
                    hash={data as string}
                />,
            )
        }
    }, [error, isError, isSuccess])

    return {
        handleBuyNft,
        isError,
        isPending,
    }
}

export const useCreateListing = (
    nftId: number,
    closeModalPriceNft: () => void,
    onDone?: () => void,
) => {
    const marketplaceContract = getContractByName('marketplace')
    const nftContract = getContractByName('nft')
    const { openModal, closeModal } = useModalContext()

    const { writeContract, isError, isPending, error, isSuccess, data } =
        useWriteContract()

    const handleCreateListingNft = (price: number) => {
        try {
            writeContract({
                abi: NftMarketPlaceAbi,
                address: marketplaceContract as `0x${string}`,
                functionName: 'createListing',
                args: [
                    nftContract,
                    '0x0000000000000000000000000000000000000001',
                    nftId,
                    parseEther(price.toString(), 'wei'),
                ],
            })
        } catch (error) {
            console.error('error: create listing nft :>> ', error)
        }
    }

    useEffect(() => {
        if (isError && error) {
            const errorToast =
                (error as BaseError).shortMessage || error.message
            console.error('error create listing :>> ', error)

            toast.error(errorToast)
        }
        if (isSuccess) {
            closeModalPriceNft()
            openModal(
                <ModalViewTransactionHash
                    closeModal={closeModal}
                    hash={data as string}
                />,
            )
            setTimeout(() => {
                onDone && onDone()
            }, 3100)
        }
    }, [error, isError, isSuccess])

    return {
        handleCreateListingNft,
        isError,
        isPending,
    }
}

export const useCancelListing = (onDone?: () => void) => {
    const marketplaceContract = getContractByName('marketplace')
    const { openModal, closeModal } = useModalContext()

    const { writeContract, isError, isPending, isSuccess, error, data } =
        useWriteContract()

    const handleCancelListingNft = (auctionIndex: number) => {
        try {
            writeContract({
                abi: NftMarketPlaceAbi,
                address: marketplaceContract as `0x${string}`,
                functionName: 'cancelListing',
                args: [auctionIndex],
            })
        } catch (error) {
            console.error('error: create listing nft :>> ', error)
        }
    }
    useEffect(() => {
        if (isError && error) {
            const errorToast =
                (error as BaseError).shortMessage || error.message
            console.error('error cancel listing :>> ', error)

            toast.error(errorToast)
        }
        if (isSuccess) {
            openModal(
                <ModalViewTransactionHash
                    closeModal={closeModal}
                    hash={data as string}
                />,
            )
            setTimeout(() => {
                onDone && onDone()
            }, 3100)
        }
    }, [error, isError, isSuccess])

    return {
        handleCancelListingNft,
        isError,
        isPending,
    }
}

export const useGetListing = (idListing: number, timeInterval?: number) => {
    const marketplaceContract = getContractByName('marketplace')

    const { data, isLoading } = useReadContract({
        address: marketplaceContract as `0x${string}`,
        abi: NftMarketPlaceAbi,
        functionName: 'getListing',
        args: [idListing],
        query: {
            refetchInterval: 10000,
        },
    })

    const dataNft = data as {
        addressNFTCollection: string
        addressPaymentToken: string
        index: number
        nftId: number
        owner: string
        price: bigint
        status: number
    }

    return {
        dataNft: (dataNft?  {
            price: dataNft ? Number(dataNft?.price) : 0,
            index: dataNft ? dataNft?.index : 0,
            priceFormat: dataNft ? formatEther(dataNft?.price) : 0,
            owner: dataNft ? dataNft?.owner : '',
        } : null) as {
            price: number,
            index: number,
            priceFormat: string,
            owner: string
        },
        isLoading,
    }
}

export const useApproveNft = () => {
    const { address } = useAccount()
    const marketplaceContract = getContractByName('marketplace')
    const nftContract = getContractByName('nft')
    const { openModal, closeModal } = useModalContext()

    const { writeContract, isError, isPending, error, isSuccess, data } =
        useWriteContract()
    const handleApprove = async () => {
        try {
            await writeContract({
                abi: NftAbi,
                account: address,
                address: nftContract as `0x${string}`,
                functionName: 'setApprovalForAll',
                args: [marketplaceContract, true],
            })
        } catch (error) {
            console.log('error :>> ', error)
        }
    }

    useEffect(() => {
        if (isError && error) {
            const errorToast =
                (error as BaseError).shortMessage || error.message

            toast.error(errorToast)
        }
        if (isSuccess) {
            openModal(
                <ModalViewTransactionHash
                    closeModal={closeModal}
                    hash={data as string}
                />,
            )
        }
    }, [error, isError, isSuccess])

    return {
        handleApprove,
        isError,
        isSuccessApproved: isSuccess,
        isPending,
    }
}

export const useGetAllowance = (idNft: number) => {
    const nftContract = getContractByName('nft')
    const marketplaceContract = getContractByName('marketplace')

    const { currentUser } = useAuth()
    const { data, isLoading } = useReadContract({
        address: nftContract as `0x${string}`,
        abi: NftAbi,
        functionName: 'isApprovedForAll',
        args: [currentUser?.user?.connect_wallet_address, marketplaceContract],
        query: {
            refetchInterval: 10000,
        },
    })

    return {
        isAllowance: !!data,
        isLoading,
    }
}

export const useGetOwnerOfNft = (idNft: number) => {
    const nftContract = getContractByName('nft')

    const { data, isLoading } = useReadContract({
        address: nftContract as `0x${string}`,
        abi: NftAbi,
        functionName: 'ownerOf',
        args: [idNft],
        query: {
            refetchInterval: 10000,
        },
    })

    return {
        ownerNft: data?.toString(),
        isLoading,
    }
}
