import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'

const FormSearch = ({
    onChange,
    value,
}: {
    onChange: (e: { target: { value: any } }) => void
    value: string
}) => {
    return (
        <form
            className="sm:w-[250px] h-[36px] flex !rounded-lg flex-1 bg-[#16202D] pl-2"
            action="#"
            method="GET"
        >
            <label htmlFor="search-field" className="sr-only">
                Search
            </label>
            <div className="relative w-full">
                <MagnifyingGlassIcon
                    className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-500"
                    aria-hidden="true"
                />
                <input
                    id="search-field"
                    className="block  h-full w-full border-0 bg-transparent py-0 pl-8 pr-2 text-white focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    type="search"
                    name="search"
                    value={value}
                    onChange={onChange}
                    // onClick={() => setSidebarOpen(true)}
                />
            </div>
        </form>
    )
}

export default FormSearch
