import { arbitrumSepolia } from 'wagmi/chains'

import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'

const metadata = {
    name: 'Web3Modal',
    description: 'Web3Modal Example',
    url: 'https://web3modal.com', // origin must match your domain & subdomain
    icons: ['https://avatars.githubusercontent.com/u/37784886'],
}

export const projectId = '7628a90a8684d48e21cf6a5094ef707e'

const customChain = {
    ...arbitrumSepolia,
    rpcUrls: {
        default: {
            http: ['https://wispy-methodical-sound.arbitrum-sepolia.quiknode.pro/b4fb9150dc6e280762712b09b2192398faee77a8'],
        },
    }
}


export const config = defaultWagmiConfig({
    chains:
        process.env.REACT_APP_ENV === 'production'
            ? [customChain]
            : [customChain], // required
    projectId: '7628a90a8684d48e21cf6a5094ef707e', // required
    metadata, // required
})
