import {useEffect} from "react";
import useSWR from "swr";
import {atom, useAtom} from "jotai";
import {fetcher} from "../../utils/fetcher";


export const ethPriceAtom = atom<number>(0)

export const useFetchPriceEth = () => {
   const {data } = useSWR(`${process.env.REACT_APP_API_AUTH}/api/get-eth-price`, fetcher)
    const [ethPrice, setEthPrice] = useAtom(ethPriceAtom)

    useEffect(() => {
    if(data){
        setEthPrice(Number(data?.data?.eth_price))
    }
    }, [data?.data]);
}


export const useGetPriceEth = () => {
    const [ethPrice, setEthPrice] = useAtom(ethPriceAtom)
    return ethPrice
}