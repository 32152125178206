import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

type Props = {}

export default function Breadcrumb(props: Props) {
    const location = useLocation()
    const navigator = useNavigate()

    const listNav: { [key: string]: any[] } = {
        '/profile': [
            {
                title: 'Profile',
                href: '',
            },
        ],
        '/news': [
            {
                title: 'News',
                href: '',
            },
        ],
        '/marketplace': [
            {
                title: 'Marketplace',
                href: '',
            },
        ],
        '/marketplace/staff': [
            {
                title: 'Marketplace',
                href: '/marketplace',
            },
            {
                title: 'Staff',
                href: '',
            },
        ],
        '/marketplace/egg': [
            {
                title: 'Marketplace',
                href: '/marketplace',
            },
            {
                title: 'Egg',
                href: '',
            },
        ],
        '/marketplace/accessories': [
            {
                title: 'Marketplace',
                href: '/marketplace',
            },
            {
                title: 'Accessories',
                href: '',
            },
        ],
        '/profile/setting': [
            { title: 'Profile', href: '/profile' },
            {
                title: 'Setting',
                href: '',
            },
        ],
    }


    if (location?.pathname === '/') return null

    const listBreadcrumb =
        location && listNav[location?.pathname]
            ? listNav[location?.pathname]
            :  location?.pathname.startsWith("/news/") ?  [
                {
                    title: 'News',
                    href: '/news',
                },
                {
                    title: 'Detail',
                    href: '',
                },
            ]: [
                  {
                      title: 'Marketplace',
                      href: '/marketplace',
                  },
                  {
                      title: 'Detail',
                      href: '',
                  },
              ]


    return (
        <nav aria-label="Breadcrumb">
            <ol className="flex items-center gap-1 text-sm text-gray-600">
                <li>
                    <p
                        onClick={() => navigator('/')}
                        className="block cursor-pointer transition text-[#8e97a8]"
                    >
                        <span className="sr-only"> Home </span>

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                            />
                        </svg>
                    </p>
                </li>

                {listBreadcrumb?.length === 1 && (
                    <li className="rtl:rotate-180">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fillRule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </li>
                )}

                {listBreadcrumb?.map((e) => {
                    return (
                        <>
                            {listBreadcrumb?.length > 1 && (
                                <li className="rtl:rotate-180">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </li>
                            )}

                            <li>
                                <p
                                    onClick={() =>
                                        navigator(e?.href || location?.pathname)
                                    }
                                    className="block cursor-pointer transition text-[#8e97a8]"
                                >
                                    {e?.title}
                                </p>
                            </li>
                        </>
                    )
                })}

                {/* <li className="rtl:rotate-180">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fillRule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            clipRule="evenodd"
                        />
                    </svg>
                </li>

                <li>
                    <a href="#" className="block transitio text-white">
                        Xpet
                    </a>
                </li> */}
            </ol>
        </nav>
    )
}
