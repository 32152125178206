import { formatNumberWithNumeral } from 'src/lib/wagmi/utils/formatNumber'
import useSWR from 'swr'
import { MarketStats } from '../interfaces'
import { fetcher } from 'src/utils/fetcher'
import logoEth from '../../../assets/images/web/eth.svg'

const StatsListNft = () => {
    const { data: marketStats } = useSWR(
        `${process.env.REACT_APP_API}/v1/marketplace/stats-pet`,
        fetcher,
    )

    const marketStatsData = marketStats?.data as MarketStats

    return (
        <div className="flex gap-4 items-center w-full">
            <div className="bg-[#16202D] sm:w-[136px] p-2 rounded-lg">
                <p className="sm:text-sm text-[12px] text-[#959595]">
                    Total PET
                </p>
                <p className="text-white sm:text-xl text-[12px] font-bold">
                    {formatNumberWithNumeral(marketStatsData?.total_nft ?? 0)}
                </p>
            </div>
            <div className="bg-[#16202D] sm:w-[136px] p-2 rounded-lg">
                <p className="sm:text-sm text-[12px] text-[#959595]">Owners</p>
                <p className="text-white sm:text-xl text-[12px] font-bold">
                    {formatNumberWithNumeral(
                        marketStatsData?.unique_owner ?? 0,
                    )}
                </p>
            </div>
            <div className="bg-[#16202D] sm:w-[136px] p-2 rounded-lg">
                <p className="sm:text-sm text-[12px] text-[#959595]">
                    Volume 24h
                </p>
                <div className={"flex items-center"}>
                    <p className="text-white sm:text-xl text-[12px] font-bold">
                        {formatNumberWithNumeral(marketStatsData?.volume_24h ?? 0)}
                    </p>
                    <img src={logoEth} alt={"logo etht"} className={"w-5 h-5 ml-`"}/>
                </div>
            </div>
            <div className="bg-[#16202D] sm:w-[136px] p-2 rounded-lg">
                <p className="sm:text-sm text-[12px] text-[#959595]">Listed</p>
                <p className="text-white sm:text-xl text-[12px] font-bold">
                    {formatNumberWithNumeral(
                        marketStatsData?.unique_listed ?? 0,
                    )}
                </p>
            </div>
        </div>
    )
}

export default StatsListNft
