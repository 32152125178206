import ethLogo from "../../../assets/images/web/eth.svg";
import { Button } from 'src/components/ui/button'
import {truncateAddress} from "../../../lib/wagmi/utils/truncateAddress";
import {formatNumberWithNumeral} from "../../../lib/wagmi/utils/formatNumber";
import React from "react";
import {NftListingResponse} from "../interfaces";
import {useWindow} from "../../../lib/context/WindowContext";
import {useNavigate} from "react-router-dom";
const NewlyListed = ({listNftListingData, setListNftListingData}: {listNftListingData: NftListingResponse[], setListNftListingData: (val:NftListingResponse[] ) => void}) => {
    const navigate =  useNavigate()
    const onDone = (idNft: number) => {
        const newList = listNftListingData.filter(
            (e) => e?.nft?.nft_id !== idNft,
        )
        setListNftListingData(newList)
    }
    const { isMobile } = useWindow()
    if(isMobile){
        return (

            <div
                className={"flex font-medium w-full justify-center mt-4 flex-wrap gap-4"}>
                {
                    listNftListingData?.map((e, idx) => {
                        return (
                            <a
                                key={`item-mobile-${idx}`}
                                href={`/marketplace/staff/${
                                    e?.nft?.nft_id
                                }`}
                            >
                                <div  className={"text  w-[280px] xs:w-[250px] hover:border-sky-500 cursor-pointer border pt-3 pb-4 px-4 border-[#737d8f] text-white rounded-xl"}>
                                   <div className={"w-full"}>
                                       <img className={"w-[120px] mx-auto"} src={e?.nft?.image} alt={"logo nft"} />
                                       <p className={"text-white text-lg truncate font-semibold"}>
                                           {e?.nft?.name}
                                       </p>
                                       <div  className={"flex py-2 justify-between"}>
                                           <p className={"text-[#737d8f]"}>
                                               Owner:
                                           </p>

                                           <p className={"text-white"}>
                                               {e?.seller_name} <span className={"text-[#737d8f]"}>
                                        ({truncateAddress(e?.owner_address, 3)})
                                    </span>
                                           </p>
                                       </div>
                                       <div className={"flex justify-between"}>
                                           <p className={"pr-2 text-[#737d8f]"}>
                                               Price:
                                           </p>
                                           <div className={"flex"}>
                                               <img src={ethLogo} alt={"logo eth"} className={"w-5 h-5"} />
                                               <p className={"pl-1"}>
                                                   {formatNumberWithNumeral(
                                                       e?.price,
                                                   )}
                                               </p>
                                           </div>
                                       </div>
                                       <div className={"w-full mt-6 flex flex-col"}>
                                           <a href={`/marketplace/staff/${
                                               e?.nft?.nft_id
                                           }`}
                                              className={"w-full"}
                                           >
                                               <Button className={"w-full main-button"}>
                                                   Detail
                                               </Button>
                                           </a>
                                       </div>
                                   </div>
                                </div>
                            </a>


                        )
                    })
                }

            </div>
        )
    }

    return (
        <div>
            <div className="table-wrapper style-scroll">
                <table className="text-[14px] table-auto w-[800px] md:w-full table-market">
                    <thead className="text-left h-[36px]">
                    <tr className="text-[#506683]">
                        <th className="px-8">NAME</th>
                        <th className="px-8">SELLER</th>
                        <th className="px-8">PRICE</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {listNftListingData.map((e) => (
                        <tr
                            key={`item-new-list-${e.nft.nft_id}`}
                            onClick={() => navigate(`/marketplace/staff/${
                                e?.nft?.nft_id
                            }`) }
                            className={"cursor-pointer"}
                        >
                            {/* Use nft_id as key */}
                            <td>
                                <div className="flex items-center gap-3">
                                    <div className="avatar">
                                        <div className="w-20 rounded">
                                            <img
                                                className="!object-contain"
                                                src={
                                                    e?.nft
                                                        ?.image
                                                }
                                                alt="Avatar Tailwind CSS Component"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="font-bold text-[18px]">
                                            {e?.nft?.name}
                                        </div>
                                        <div className="text-[#5C789C] text-[14px]">
                                            <p className="truncate max-w-[100px]">
                                                {
                                                    e?.nft
                                                        ?.description
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="flex items-center gap-3">
                                    <div className="avatar !w-10">
                                        <img
                                            className="!w-10 !h-10 rounded-full"
                                            src={
                                                e?.seller_avatar
                                            }
                                            alt="Avatar"
                                        />
                                    </div>
                                    <div>
                                        <div className="font-bold">
                                            {e?.seller_name}
                                        </div>
                                        <div className="text-[14px] opacity-50">
                                            (
                                            {truncateAddress(
                                                e?.owner_address,
                                            )}
                                            )
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="flex items-center gap-1">
                                    <img
                                        className="w-5 h-5"
                                        src={ethLogo}
                                        alt="price"
                                    />
                                    <span className="font-bold text-xl">
                                                            {formatNumberWithNumeral(
                                                                e?.price,
                                                            )}
                                                        </span>
                                </div>
                            </td>
                            <td>
                                <div className="w-[100px] inline-flex items-center justify-center gap-x-1.5 rounded-[10px]">

                                    <a
                                        href={`/marketplace/staff/${
                                            e?.nft?.nft_id
                                        }`}
                                    >
                                        <Button
                                            className="main-button"
                                        >
                                            Detail
                                        </Button>
                                    </a>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>



        </div>


    )
}
export  default NewlyListed