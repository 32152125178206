import { ArrowRight, CheckCircle } from 'lucide-react'
import { Button } from '../ui/button'
import { getExplorerLink } from 'src/lib/wagmi/utils/getExplorerLink'
import { useChainId } from 'wagmi'

const ModalViewTransactionHash = ({
    hash,
    closeModal,
}: {
    hash: string
    closeModal: () => void
}) => {
    const chainID = useChainId()
    const linkTransaction = getExplorerLink(chainID, hash, 'transaction')
    return (
        <div>
            <CheckCircle className="w-[80px] text-[#10B981] mx-auto h-[80px]" />
            <p className="text-white mt-4 text-lg text-center font-semibold">
                Transaction Submitted
            </p>
            <p className="text-sm text-center font-normal mt-4 text-[#F1F5F9]">
                Your transaction has been successfully submitted! To verify its
                status, please check it on Blockchain Explorer.
            </p>
            <div className="w-full flex justify-center">
                <a
                    className="text-blue-500 text-center mt-2"
                    rel="noreferrer"
                    href={linkTransaction}
                    target="_blank"
                >
                    <div className="flex gap-2">
                        View Transaction Hash
                        <ArrowRight />
                    </div>
                </a>
            </div>
            <Button onClick={closeModal} className="mt-6 w-full">
                Got it
            </Button>
        </div>
    )
}

export default ModalViewTransactionHash
