import InfiniteScroll from 'react-infinite-scroll-component'
import { NftListingResponse } from '../interfaces'
import CardNft from './CardNft'
import { Loading } from 'src/components/ui/Loading'

const ListNft = ({
    listNft,
    setPage,
    currentListNftListingData,
    page,
}: {
    listNft: NftListingResponse[]
    setPage: (val: number) => void
    currentListNftListingData: NftListingResponse[]
    page: number
}) => {
    return (
        <div className="w-full flex flex-col py-6 ">
            <InfiniteScroll
                dataLength={listNft?.length}
                next={() => {
                    setPage(page + 1)
                }}
                hasMore={currentListNftListingData.length === 20}
                loader={<Loading />}
                scrollThreshold={0.9}
                endMessage={
                    <p style={{ textAlign: 'center' }} className="mt-3">
                        <b className="text-white"> Yay! You have seen it all</b>
                    </p>
                }
            >
                <div className="w-full flex flex-wrap justify-center style-scroll-hidden md:justify-normal  gap-4">
                    {listNft.map((item, index) => (
                        <CardNft item={item} key={`item-list-nft-${index}`} />
                    ))}
                </div>
            </InfiniteScroll>
        </div>
    )
}

export default ListNft
