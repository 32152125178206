import * as React from "react"

import {cn} from "src/utils"

export interface InputProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    error?: string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    ({className, type, error, ...props}, ref) => {
        return (
            <>
                <input
                    type={type}
                    className={cn(
                        'flex h-10 w-full rounded-md border main-text-primary main-bg-default px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:main-text-muted focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
                        className
                    )}
                    ref={ref}
                    {...props}
                />
                {error && (
                    <p className="text-red-500 text-xs mt-1">{error}</p>
                )}
            </>
        )
    }
)
Input.displayName = "Input"

export {Input}
