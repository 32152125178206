import { get, post, put, del } from './axiosClient'

const landingPageApi = {
    getListPostNews(data: any): Promise<any> {
        const url = '/news/list'
        return post(url, data, undefined, false)
    },
    getNewDetail(data: any): Promise<any> {
        const url = `/news/detail`
        return post(url, data, undefined, false)
    },
}

export default landingPageApi
