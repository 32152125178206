import { atom, useAtom } from 'jotai'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useNavigate } from 'react-router-dom'
import { post } from '../../../api/axiosClient'
import top from '../../../assets/images/web/Top.png'
import EmptyState from '../../../components/Common/EmptyState'
import { Loading } from '../../../components/ui/Loading'
import { AniBarPost } from '../interfaces/news'

export const newBgImages = [
    require('../../../assets/images/web/news_bg_1.png'),
    require('../../../assets/images/web/news_bg_2.png'),
    require('../../../assets/images/web/news_bg_3.png'),
]

type Props = {}

const dataNewListAtom = atom<{
    newsListData: AniBarPost[]
    currentList: AniBarPost[]
    page: number
}>({
    newsListData: [],
    currentList: [],
    page: 1,
})

const NewsPage = (props: Props) => {
    const navigate = useNavigate()
    const [dataNewList, setDataNewList] = useAtom(dataNewListAtom)
    const { newsListData, page, currentList } = dataNewList
    const [isLoading, setIsLoading] = useState(false)

    const fetchNews = async (page: number) => {
        setIsLoading(true)
        const response = await post(
            `${process.env.REACT_APP_API_NEWS}/list?page=${page}&page_size=3`,
            {},
            {},
            false,
        ).finally(() => setIsLoading(false))

        if (response && response.data) {
            setDataNewList((prev) => ({
                ...prev,
                totalPage: response.total_pages, // Ensure this is the correct property from your API
                newsListData:
                    page === 1
                        ? response.data
                        : [...prev.newsListData, ...response.data],
                currentList: response.data,
                page,
            }))
        }
    }

    useEffect(() => {
        fetchNews(1)
    }, [])

    const loadMoreNews = () => {
        fetchNews(page + 1)
    }

    const getRandomImage = useRandomImage()

    return (
        <div className="text-white">
            <div className="w-full pb-10 flex justify-center">
                <h2 className="font-bold text-[40px]">News</h2>
            </div>
            {isLoading && newsListData?.length === 0 ? (
                <Loading />
            ) : newsListData && newsListData.length > 0 ? (
                <InfiniteScroll
                    dataLength={newsListData.length}
                    next={loadMoreNews}
                    hasMore={currentList?.length === 3}
                    scrollThreshold={0.7}
                    loader={<Loading />}
                    endMessage={
                        <p style={{ textAlign: 'center' }} className="mt-3">
                            <b>You have seen all the news</b>
                        </p>
                    }
                >
                    <div className="w-full flex flex-col justify-center gap-[24px]">
                        {newsListData?.map((news) => {
                            const img = getRandomImage()

                            console.log('img', img)
                            if (img === null) {
                                return null
                            }
                            return (
                                <div
                                    key={`news-${news.id}`}
                                    className="
                relative
                w-full
                bg-no-repeat
                bg-cover
                min-h-[300px]
                rounded-[20px]
                overflow-hidden
            "
                                    style={{
                                        backgroundImage: `url(${
                                            newBgImages[news.id % 3]
                                        })`,
                                    }}
                                >
                                    <div className="inset-0 min-h-[300px] bg-black bg-opacity-50  flex items-center p-5">
                                        <div className="w-full flex flex-col">
                                            <h3 className="font-bold text-white text-[24px] sm:text-3xl leading-tight">
                                                {news.name}
                                            </h3>
                                            <p className="text-[14px] sm:text-lg text-[#C2CBD2] mt-2">
                                                {news.description}
                                            </p>

                                            <button
                                                className="
                            mt-4 sm:mt-8
                            w-[163px]
                            h-[45px]
                            rounded-full
                            flex
                            items-center
                            justify-center
                            bg-white bg-opacity-30
                            text-white
                            text-[15px]
                        "
                                                onClick={() =>
                                                    navigate(`/news/${news.id}`)
                                                }
                                            >
                                                Explore
                                                <img
                                                    src={top}
                                                    alt="arrow"
                                                    className="ml-2 rotate-90 w-[12px] h-[6px]"
                                                />
                                            </button>
                                        </div>
                                        <div
                                            className="
                                    w-1/3
                                    sm:flex
                                        mt:3
                                        sm:mt-0
                                        hidden
                                    items-center
                                    justify-center
                                "
                                        >
                                            <img
                                                className="
                                        w-full
                                        max-w-[216px]
                                        max-h-[216px]
                                        object-contain
                                    "
                                                alt="icon"
                                                src={img as string}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </InfiniteScroll>
            ) : (
                <EmptyState />
            )}
        </div>
    )
}

export function useRandomImage() {
    const imagePaths = [
        require('../../../assets/images/web/icon-new-1.png'),
        require('../../../assets/images/web/icon-new-2.png'),
        require('../../../assets/images/web/icon-new-3.png'),
    ]
    const [imageIndices, setImageIndices] = useState([])

    useState(() => {
        const shuffledIndices = imagePaths
            .map((_, index) => index)
            .sort(() => Math.random() - 0.5)
        // @ts-ignore
        setImageIndices(shuffledIndices)
    })

    function getRandomImage() {
        if (imageIndices.length === 0) {
            const shuffledIndices = imagePaths
                .map((_, index) => index)
                .sort(() => Math.random() - 0.5)
            // @ts-ignore
            setImageIndices(shuffledIndices)
        }
        const randomIndex = imageIndices.pop() // Lấy chỉ số cuối cùng từ mảng và loại bỏ nó
        // @ts-ignore
        return imagePaths[randomIndex]
    }

    return getRandomImage
}

export default NewsPage
