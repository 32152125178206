import {useState} from 'react'
import ShadModal from 'src/components/ui/ShadModal'
import {Button} from 'src/components/ui/button'
import {useModal} from 'src/lib/hooks/useModal'
import {StepModalLogin} from "./interfaces";
import StepInfo from "./components/StepInfo";
import StepLogin from "./components/StepLogin";
import StepSignUp from "./components/StepSignUp";
import StepForgotPasswordComponent from "./components/StepForgotPassword";
import {ArrowLeft} from "lucide-react";


const LoginModal = ({className}: { className?: string }) => {
    const [stepModal, setStepModal] = useState(StepModalLogin.INFO);
    const {open, openModal, closeModal, setOpen} = useModal();

    const renderStepContent = () => {
        switch (stepModal) {
            case StepModalLogin.INFO:
                return <StepInfo handleNextStep={setStepModal}/>;
            case StepModalLogin.SIGN_IN:
                return <StepLogin setStepModal={setStepModal}/>;
            case StepModalLogin.SIGN_UP:
                return <StepSignUp closeModal={closeModal}/>;
            case StepModalLogin.FORGOR_PASSWORD:
                return <StepForgotPasswordComponent onBack={() => setStepModal(StepModalLogin.SIGN_IN)}/>;
            default:
                return null;
        }
    };

    return (
        <>
            <Button onClick={openModal} className={`main-button !w-[110px] ${className}`}>
                Login
            </Button>
            <ShadModal isOpen={open} onOpen={setOpen} className="w-full !max-w-3xl h-[90%] sm:h-fit !p-0">
                <div className="flex bg-[#10161F] rounded-lg pt-4 sm:pt-0">
                    <div className="w-full p-6 sm:w-[55%]">
                        {
                            stepModal !== StepModalLogin.INFO &&
                            <ArrowLeft
                                onClick={() => setStepModal(StepModalLogin.INFO)}
                                className={"mb-6 cursor-pointer"}/>
                        }
                        {renderStepContent()}
                    </div>
                    <div className="w-[45%] hidden sm:flex">
                        <img alt="" className="rounded-r-lg"
                             src="https://cdn.axieinfinity.com/marketplace-website/banner/login-background.png"/>
                    </div>
                </div>
            </ShadModal>
        </>
    );
}

export default LoginModal