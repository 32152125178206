import React, {useState, useEffect} from 'react'
import Navbar from 'src/features/landing/components/Navbar'
import Footer from '../../landing/components/Footer'
import { useParams } from 'react-router-dom'
import landingPageApi from '../../../api/landingPageApi'
import moment from 'moment'

type Props = {}

const PostDetail = (props: Props) => {
    // get id from url
    const { id } = useParams()

    const [newDetail, setNewDetail] = useState<any>(null)
    // get new detail from api
    useEffect(() => {
        if (!id) return

        // call api get new detail
        let data = {
            id: Number(id),
        }
        landingPageApi.getNewDetail(data)
            .then(response => {
                setNewDetail(response)
            })
            .catch(error => {
                console.log('Failed to fetch new detail: ', error)
            })
    }, [id])

    // convert string to html
    const createMarkup = (html: string) => {
        return { __html: html }
    }

    return (
        <div className='font-kavoon font-sans'>
            <Navbar />
            {newDetail && (
                <>
                    <div className='w-full mb-[30px] text-center'>
                        <button 
                            className='button-storke mt-[100px]'
                        >
                            The Lunacian
                        </button>
                    </div>
                    <div className='w-full py-[20px] px-4 flex flex-col'>
                        <div className='max-w-[1024px] w-full mx-auto flex flex-wrap gap-3 justify-center text-white font-medium whitespace-break-spaces'>
                            <div className='text-[#FAFAFA] text-[1rem] w-full'>
                                COMMUNITY
                            </div>
                            <div className='text-[#1CF1FF] text-[2rem] w-full'>
                                {newDetail.name}
                            </div>
                            <div className='text-[#FAFAFA] text-[1rem] w-full'>
                                {newDetail.description}
                            </div>
                            <div className='text-[#FAFAFA] text-[1rem] w-full'>
                                {moment(newDetail.create_time_at).format('DD MMM YYYY')}
                            </div>
                        </div>
                    </div>
                    <div className='w-full py-[20px] px-4 flex flex-col'>
                        <div className='max-w-[1024px] w-full mx-auto flex flex-wrap gap-5 justify-center text-white font-medium whitespace-break-spaces'>
                            <hr className='w-full' style={{borderColor: 'rgba(255, 255, 255, 0.2)'}}/>
                            {newDetail.content && (
                                <div
                                    className='
                                        w-full
                                    '
                                    dangerouslySetInnerHTML={createMarkup(newDetail.content)}
                                />
                            )}
                        </div>
                    </div>
                </>
            )}
            <Footer />
        </div>
    )
}

export default PostDetail