import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useCallback, useMemo, useState } from 'react'
import ShadModal from 'src/components/ui/ShadModal'
import { Button } from 'src/components/ui/button'
import LoginModal from 'src/features/Login'
import { useAuth } from 'src/lib/context/AuthContext'
import { useModal } from 'src/lib/hooks/useModal'
import { cn } from 'src/utils/common'
import { preventBubbling } from 'src/utils/preventBubbling'
import { useAccount } from 'wagmi'
import shoppingCart from '../../../../assets/images/web/shopping-cart.png'
import {
    useApproveNft,
    useBuyNft,
    useCancelListing,
    useCreateListing,
    useGetAllowance,
    useGetListing,
} from '../../services/marketplace.service'

export enum StatusNft {
    ACTIVE = 1,
    PLAYING,
    LISTING,
    REMOVE = -1,
}

const ButtonBuyNft = ({
    idListing,
    ownerAddress,
    idNft,
    isIcon = true,
    onDoneCancel,
    onDoneCreate,
    onDoneBuy,
    classNameButtonLogin,
                          timeInterval
}: {
    idListing: number
    ownerAddress: string
    idNft: number
    isIcon?: boolean
    onDoneCancel?: () => void
    onDoneCreate?: () => void
    onDoneBuy?: () => void
    classNameButtonLogin?: string
    timeInterval?: number
}) => {
    const { currentUser } = useAuth()
    const {connect_wallet_address} = currentUser?.user ?? {}
    const { dataNft } = useGetListing(idListing, timeInterval)
    const { open: isOpenModal, setOpen, openModal, closeModal } = useModal()
    const [price, setPrice] = useState('')
    const { handleBuyNft, isPending: isPendingBuyNft } = useBuyNft(
        idListing,
        onDoneBuy,
    )
    const { handleCancelListingNft, isPending: isPendingCancelListing } =
        useCancelListing(onDoneCancel)

    const {
        handleApprove,
        isPending: isLoadingApprove,
        isSuccessApproved,
    } = useApproveNft()
    const { isAllowance, isLoading: isLoadingAllowance } =
        useGetAllowance(idNft)

    const { address } = useAccount()

    const { handleCreateListingNft, isPending: isPendingCreateListing } =
        useCreateListing(idNft, closeModal, () => {
            onDoneCreate && onDoneCreate()
        })
    const { open } = useWeb3Modal()

    const isCancel = useMemo(
        () =>
            connect_wallet_address?.toLowerCase() ===
            dataNft?.owner?.toLowerCase(),
        [connect_wallet_address, dataNft?.owner],
    )

    const handleButton = useCallback(async () => {
        if (
            !address ||
            address?.toLowerCase() !==
                connect_wallet_address?.toLowerCase()
        ) {
            open()
        } else if (isCancel) {
            await handleCancelListingNft(dataNft?.index)
        } else if (
            ownerAddress?.toLowerCase() ===
            connect_wallet_address?.toLowerCase()
        ) {
            openModal()
        } else {
            await handleBuyNft(dataNft?.price)
        }
    }, [
        address,
        connect_wallet_address,
        dataNft?.index,
        dataNft?.price,
        handleBuyNft,
        handleCancelListingNft,
        isCancel,
        open,
        openModal,
        ownerAddress,
    ])

    const isPending = useMemo(
        () => isPendingBuyNft || isPendingCancelListing,
        [isPendingBuyNft, isPendingCancelListing],
    )

    const isApproved = useMemo(
        () => isAllowance || isSuccessApproved,
        [isAllowance, isSuccessApproved],
    )

    const handleButtonCreateListing = useCallback(() => {
        if (isApproved) {
            handleCreateListingNft(Number(price))
        } else {
            handleApprove()
        }
    }, [handleApprove, handleCreateListingNft, isApproved, price])

    const isNotYetListing = useMemo(
        () =>
            !dataNft &&
            ownerAddress?.toLowerCase() !==
                connect_wallet_address?.toLowerCase(),
        [connect_wallet_address, dataNft, ownerAddress],
    )

    return (
        <>
            <ShadModal
                isOpen={isOpenModal}
                className=" sm:!max-w-[448px] !w-full"
                onOpen={setOpen}
            >
                <div>
                    <p className="font-bold pb-6 text-xl text-white text-center">
                        {' '}
                        Create Listing{' '}
                    </p>
                    <div className="w-full mb-4 flex justify-between items-center">
                        <p className="text-white">Token Pay</p>
                        <img
                            className="w-6 h-6"
                            src="https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png"
                            alt="logo eth"
                        />
                    </div>

                    <p className="text-white pb-1">Price Listing</p>
                    <input
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        placeholder="Enter an amount"
                        className="w-full h-[40px] bg-[#161E2A] rounded focus:outline-none px-3"
                    />

                    <Button
                        onClick={handleButtonCreateListing}
                        loading={
                            isLoadingAllowance ||
                            isLoadingApprove ||
                            isPendingCreateListing
                        }
                        className="main-button !mt-6 w-full"
                    >
                        { (isLoadingAllowance || isLoadingApprove || isPendingCreateListing) ? '' : (isApproved ? 'Create Listing' : 'Approve') }
                    </Button>
                </div>
            </ShadModal>
            {currentUser ? (
                <Button
                    className={cn('main-button !h-[48px] !-w-full')}
                    onClick={preventBubbling(() => handleButton())}
                    disabled={isPending || isNotYetListing}
                    loading={isPending}
                >
                    {isIcon && (
                        <img
                            src={shoppingCart}
                            className="w-[18px] h-[18px] mr-2"
                            alt="shopping-cart"
                        />
                    )}
                    {isNotYetListing
                        ? 'Nft is not listed yet'
                        : isPending
                        ? 'Confirming...'
                        : !address
                        ? 'Connect wallet'
                        : address !== connect_wallet_address
                        ? 'Correct wallet'
                        : isCancel
                        ? 'Cancel Listing'
                        : ownerAddress?.toLowerCase() ===
                          connect_wallet_address?.toLowerCase()
                        ? 'Create Listing'
                        : 'Buy now'}
                </Button>
            ) : (
                <LoginModal className={classNameButtonLogin} />
            )}
        </>
    )
}

export default ButtonBuyNft
