export function genPramsCheckNull(params: { [key: string]: any }, url: string) {
    let baseUrl = url
    const queryParams: any = []

    for (const [key, value] of Object.entries(params)) {
        if (value || (Array.isArray(value) && value.length > 0)) {
            if (Array.isArray(value)) {
                value.forEach((item) => queryParams.push(`${key}=${item}`))
            } else {
                queryParams.push(`${key}=${value}`)
            }
        }
    }

    if (queryParams.length) {
        baseUrl += `?${queryParams.join('&')}`
    }

    return baseUrl
}
