import React from 'react'
import {
    FaDribbbleSquare,
    FaFacebookSquare,
    FaGithubSquare,
    FaInstagram,
    FaTwitterSquare,
} from 'react-icons/fa'
import logo5 from '../../../assets/images/webp/logo5.webp'
import Top from '../../../assets/images/webp/Top.webp'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
    const navigate = useNavigate()
    const handleScrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    return (
        <div className="w-full h-[250px] bg-[#45086B] mx-auto md:mt-[200px] mt-[100px]  px-4 flex flex-col">
            <div className="w-full flex flex-col justify-center items-center relative xxs:top-[-60px] md:top-[-60px] top-[-30px]">
                <img onClick={() => navigate("/", {replace: true, state: { scrollTo: 'scrollToTop' }})} src={logo5} alt="" className="xxs:w-[180px] md:w-[200px] w-[100px] cursor-pointer transform transition hover:scale-110" />
                <button onClick={handleScrollTop} className="xxxs:mt-[-10px] md:mt-[0] md:w-[70px] md:h-[60px] w-[46px] h-[36px] translate-x-[42vw]  md:translate-x-[45vw] md:translate-y-[-11vh] translate-y-[-6vh] drop-shadow-[0_10px_#A30F70] rounded-[50%] bg-[#BF39A1] flex justify-center items-center transform transition hover:scale-110">
                    <img src={Top} alt="" className="w-[50%]" />
                </button>
            </div>
            <div className="w-full flex flex-col gap-5 justify-center items-center text-[#CF81FF] relative md:top-[-60px] top-[-30px]">
                <ul className="flex md:flex-row flex-wrap gap-5 justify-center">
                    <li className='hover:text-white'>
                        <a href="#" onClick={(event) => {
                            event.preventDefault()
                            navigate('/', {state: { scrollTo: '.scrollToMarketplace'}})
                        
                        }}>
                            Marketplace 
                        </a>
                    </li>
                    <li className='hover:text-white'>
                        <a href="#" onClick={(event) => {
                            event.preventDefault()
                            navigate('/', { state: { scrollTo: '.scrollToNew'}})
                        }}>
                            News 
                        </a>
                    </li>
                    <li className='hover:text-white'>
                        <a href="#">
                            Whitepaper 
                        </a>
                    </li>
                    <li className='hover:text-white'>
                        <Link to={'/code-of-conduct'} reloadDocument={true}>Code of conduct</Link>
                    </li>
                    <li className='hover:text-white'>
                        <Link to={'/privacy-policy'} reloadDocument={true}>Privacy policy</Link>
                    </li>
                    <li className='hover:text-white'>
                        <Link to={'/terms-of-use'} reloadDocument={true}>Terms of use</Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Footer
