import Cookies from 'js-cookie';

export const setDataToCookie = <T>(key: string, data: T) => {
    const currentDomain = typeof window !== 'undefined' ? window.location.hostname : 'localhost';

    Cookies.set(key, JSON.stringify(data), {
        domain: currentDomain,
        secure: true,
        sameSite: 'strict',
        expires: 365,
    });
};
export const getDataFromCookie = <T>(key: string) => {
    const currentDomain = typeof window !== 'undefined' ? window.location.hostname : 'localhost';

    const data = Cookies.get(key);

    if (!data) {
        return null;
    }
    return JSON.parse(data) as T;
};

export const deleteCookie = (key: string) => {
    const currentDomain = typeof window !== 'undefined' ? window.location.hostname : 'localhost';
    Cookies.remove(key, {
        domain: currentDomain,
        secure: true,
        sameSite: 'strict',
    });
};
