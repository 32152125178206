/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Profile from 'src/features/profile/components/Profile'
import SettingProfile from 'src/features/profile/components/SettingProfile'
import { useAuth } from 'src/lib/context/AuthContext'

const ProfilePage = () => {
    const { currentUser, isLoading } = useAuth()
    const navigator = useNavigate()

    useEffect(() => {
        if (!currentUser && !isLoading) {
            navigator('/')
        }
    }, [currentUser, isLoading])

    return (
        <div>
            <Profile />
        </div>
    )
}

export default ProfilePage

export const SettingProfilePage = () => {
    const { currentUser, isLoading } = useAuth()
    const navigator = useNavigate()

    useEffect(() => {
        if (!currentUser && !isLoading) {
            navigator('/')
        }
    }, [currentUser, isLoading])

    return (
        <div className="max-w-[719px] w-full mx-auto">
            <SettingProfile />
        </div>
    )
}
