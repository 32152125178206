import { Dropdown } from '@mui/base/Dropdown'
import { Menu } from '@mui/base/Menu'
import { MenuButton } from '@mui/base/MenuButton'
import { MenuItem } from '@mui/material'
import { ArrowUpDown } from 'lucide-react'

const OrderByFilter = ({
    setOrderType,
    orderType,
}: {
    setOrderType: (val: { value: string; label: string , type: string}) => void
    orderType: {
        value: string
        label: string
        type: string
    }
}) => {
    const listSortOrderType = [
        {
            label: 'Latest',
            value: 'desc',
            type: 'listing_index'
        },
        {
            label: 'Highest ID',
            value: 'desc',
            type: 'nft_id'
        },
        {
            label: 'Lowest ID',
            value: 'asc',
            type: 'nft_id'
        },
        {
            label: 'Highest Price',
            value: 'desc',
            type: 'price'
        },
        {
            label: 'Lowest Price',
            value: 'asc',
            type: 'price'
        },
        {
            label: 'Highest Level',
            value: 'desc',
            type: 'level'
        },
        {
            label: 'Lowest Level',
            value: 'asc',
            type: 'level'
        },

    ]
    return (
        <Dropdown>
            <MenuButton>
                <div className="flex items-center text-[#959595] min-w-[120px]">
                    <ArrowUpDown className="text-[#959595] w-4 mr-2" />
                    {orderType?.label}
                </div>
            </MenuButton>
            <Menu className="bg-[#16202D] rounded-lg !mt-2">
                {listSortOrderType?.map((item, index) => {
                    return (
                        <MenuItem
                            onClick={() => setOrderType(item)}
                            key={index}
                            value={item.value}
                            className="!text-white"
                        >
                            {item.label}
                        </MenuItem>
                    )
                })}
            </Menu>
        </Dropdown>
    )
}

export default OrderByFilter
