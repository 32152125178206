/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { fetcher } from 'src/utils/fetcher'
import useSWR from 'swr'

type Props = {}

const NFTCategory = (props: Props) => {
    const {data} = useSWR(
        `${process.env.REACT_APP_API}/v1/marketplace/stats-pet`,
        fetcher,
    )

    console.log("data", data)
    const people = [
        {
            name: 'Lindsay Walton',
            role: 'Front-end Developer',
            imageUrl: '/images/web/bg-staff.svg',
            title: 'Staff',
            value: `${data?.data?.total_nft ??  0} animal`,
            href: 'staff',
            isComingSoon: false,
        },
        {
            name: 'Lindsay Walton',
            role: 'Front-end Developer',
            imageUrl: '/images/web/bg-pet.svg',
            title: 'Egg',
            value: '11.090 pet',
            href: 'egg',
            isComingSoon: true,
        },
        {
            name: 'Lindsay Walton',
            role: 'Front-end Developer',
            imageUrl: '/images/web/bg-accessories.svg',
            title: 'Accessories',
            value: '11.090 accessories',
            href: 'accessories',
            isComingSoon: true,
        },
    ]

    return (
        <div className="mb-5">
            <h2 className="font-bold text-3xl mb-5">Marketplace</h2>
            <div
                role="list"
                className="grid grid-cols-1 gap-4 sm:grid-cols-3 lg:mx-0 lg:max-w-none"
            >
                {people.map((person, idx) => (
                    <a
                        href={`/marketplace/${person.href}`}
                        key={`item-people-${idx}`}
                        className="block transition"
                    >
                        <div
                            className="card max-h-[138px] bg-no-repeat bg-cover bg-center relative"
                            style={{
                                backgroundImage: `url(${person.imageUrl})`,
                            }}
                        >
                            <div className="card-body">
                                <h2 className="card-title font-medium text-white text-3xl">
                                    {person?.title}
                                </h2>
                                <p className="text-sm font-medium text-[#AEAEAE]">
                                    {person?.isComingSoon
                                        ? 'Coming Soon'
                                        : person.value}
                                </p>
                            </div>
                        </div>
                    </a>
                ))}
            </div>
        </div>
    )
}

export default NFTCategory
