import {ChevronRight} from 'lucide-react'
import {formatNumberWithNumeral} from 'src/lib/wagmi/utils/formatNumber'
import {fetcher} from 'src/utils/fetcher'
import useSWR from 'swr'
import dollar from '../../../assets/images/dollar.svg'
import nftIcon from '../../../assets/images/web/icon-new-1.png'
import {MarketStats} from '../interfaces'
import {atom, useAtom} from "jotai";
import React, {useEffect} from "react";
import ComingSoon from 'src/components/Common/ComingSoon'
import {post} from "../../../api/axiosClient";
import {AniBarPost} from "../interfaces/news";
import {useNavigate} from "react-router-dom";
import {newBgImages, useRandomImage} from "./NewsPage";
import top from "../../../assets/images/web/Top.png";
import logoEth from '../../../assets/images/web/eth.svg'
import ethereumLogo  from '../../../assets/images/web/ethereum.svg'
import kitti from '../../../assets/images/web/kitti.svg'
import cardPayment from '../../../assets/images/web/card-payment.svg'
import userIcon from '../../../assets/images/web/user.svg'

type Props = {}

const overStatsPetAtom = atom<MarketStats | null>(null)
const newsAtom = atom<AniBarPost | null>(null)


const HomePage = (props: Props) => {
    const [overStatsPet, setOverStatsPetAtom] = useAtom(overStatsPetAtom)
    const [newAniBar, setNew] = useAtom(newsAtom)
    const navigate = useNavigate()
    const getRandomImage = useRandomImage()
    const imgRandom = getRandomImage()
    useEffect(() => {
        const handleGetNew = async () => {
            const response =     await post(`${process.env.REACT_APP_API_NEWS}/list`, null, {}, false).then((res) => res)
            if (response) {
                setNew(response?.data?.[0])
            }
        }
        handleGetNew()
    }, []);

    console.log("newAniBar", newAniBar)


    const {data} = useSWR(
        `${process.env.REACT_APP_API}/v1/marketplace/stats-pet`,
        fetcher,
    )

    useEffect(() => {
        if (data?.data) {
            setOverStatsPetAtom(data?.data)
        }
    }, [data?.data]);


    const listOverallStats = [
        {
            title: 'TOTAL OWNER',
            value: formatNumberWithNumeral(overStatsPet?.unique_owner || 0),
            isLogo: false,
            icon: userIcon,
        },
        {
            title: 'TOTAL  STAFF',
            value: formatNumberWithNumeral(overStatsPet?.total_nft || 0),
            isLogo: false,
            icon: kitti,
        },
        {
            title: 'TOTAL VOLUME',
            value: formatNumberWithNumeral(overStatsPet?.total_volume || 0),
            isLogo: true,
            icon: ethereumLogo,
        },
        {
            title: 'TOTAL TRANSACTION',
            value: formatNumberWithNumeral(overStatsPet?.total_tnx || 0),
            isLogo: false,
            icon: cardPayment,
        },
    ]

    const listCurrentStake = [
        {
            title: 'Max Cap',
            value: `${formatNumberWithNumeral(54669)} ANI`,
        },
        {
            title: 'Current Cap',
            value: `${formatNumberWithNumeral(54669)} ANI`,
        },
        {
            title: 'Fixed APR',
            value: `${Number(150).toFixed(2)}%`,
        },
    ]

    return (
        <div className="text-white">
            <div
                className="
                relative
                w-full
                bg-no-repeat
                bg-cover
                min-h-[300px]
                rounded-[20px]
                overflow-hidden
            "
                style={{ backgroundImage: `url(${newBgImages[Number(newAniBar?.id)% 3]})` }}
            >
                <div className="inset-0 min-h-[300px] bg-black bg-opacity-50  flex items-center p-5">
                    <div className="w-full flex flex-col">
                        <h3 className="font-bold text-white text-[24px] sm:text-3xl leading-tight">{newAniBar?.name}</h3>
                        <p className="text-[14px] sm:text-lg text-[#C2CBD2] mt-2">{newAniBar?.description}</p>

                        <button
                            className="
                            mt-4 sm:mt-8
                            w-[163px]
                            h-[45px]
                            rounded-full
                            flex
                            items-center
                            justify-center
                            bg-white bg-opacity-30
                            text-white
                            text-[15px]
                        "
                            onClick={() => navigate(`/news/${newAniBar?.id}`)}
                        >
                            Explore
                            <img src={top} alt="arrow" className="ml-2 rotate-90 w-[12px] h-[6px]" />
                        </button>

                    </div>
                    <div
                        className='
                                    w-1/3
                                    sm:flex
                                        mt:3
                                        sm:mt-0
                                        hidden
                                    items-center
                                    justify-center
                                '
                    >
                        <img
                            className='
                                        w-full
                                        max-w-[216px]
                                        max-h-[216px]
                                        object-contain
                                    '
                            src={imgRandom as string}
                        />
                    </div>
                </div>
            </div>

            <div className="border mt-6 border-[#263750] rounded-[20px]">
                <div className="py-3 bg-[#16202D] rounded-t-[20px] px-6 text-white text-3xl font-bold">
                    Overall Stats
                </div>
                <div className="px-6 justify-between flex flex-wrap bg-[#10161F] pt-10 pb-6 rounded-b-[20px]">
                    {listOverallStats?.map((e) => {
                        return (
                            <div
                                key={`item-stats${e?.title}`}
                                className="flex pt-3"
                            >
                                <img
                                    src={e?.icon}
                                    alt="dollar"
                                    className="w-10 h-10 sm:w-[48px] sm:h-[48px]"
                                />
                                <div className="pl-3">
                                    <p className="text-[#5A6E7D]">{e?.title}</p>
                                   <div className={"flex items-center"}>
                                       <p className="text-white text-xl font-bold">
                                           {formatNumberWithNumeral(e?.value)}
                                       </p>
                                       {
                                           e?.isLogo && <img src={logoEth} alt={"logo etht"} className={"w-5 h-5 ml-`"}/>
                                       }
                                   </div>


                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

            <div className="border mt-6 border-[#263750] rounded-[20px]">
                <div className="py-3 bg-[#16202D] rounded-t-[20px] px-6 text-white text-3xl font-bold">
                    Current Stake
                </div>
                <div className="px-6 justify-between flex-wrap flex bg-[#10161F] pt-10 pb-6 rounded-b-[20px]">
                    {/* {listCurrentStake?.map((e) => {
                        return (
                            <div
                                key={`item-stats${e?.title}`}
                                className="flex pt-3"
                            >
                                <img src={dollar} alt="dollar"/>
                                <div className="pl-3">
                                    <p className="text-[#5A6E7D]">{e?.title}</p>
                                    <p className="text-white text-xl font-bold">
                                        {e?.value}
                                    </p>
                                </div>
                            </div>
                        )
                    })} */}
                    <ComingSoon />
                </div>
            </div>
        </div>
    )
}

export default HomePage
