import { createSlice } from '@reduxjs/toolkit'

export interface AppState {
    sidebarOpen: boolean
}

const initialState: AppState = {
    sidebarOpen: false,
}

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        toggleSidebar(state) {
            state.sidebarOpen = !state.sidebarOpen
        },
    },
})

// actions
export const appActions = appSlice.actions

// selectors

// reducer
const appReducer = appSlice.reducer
export default appReducer
