/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/alt-text */
import {Dialog, Transition} from '@headlessui/react'
import {
    Bars3Icon,
    Cog6ToothIcon,
    HomeIcon,
    NewspaperIcon,
    ShoppingBagIcon,
    UserIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import React, {Fragment, useMemo, useState} from 'react'
import {Link, Outlet, useLocation, useNavigate} from 'react-router-dom'
import LoginModal from 'src/features/Login'
import {useAuth} from 'src/lib/context/AuthContext'
import logoHeader from '../../assets/images/web/logo-header.png'
import Breadcrumb from '../Common/Breadcrumb'
import {ChevronDownIcon, Copy, LogOutIcon} from "lucide-react";
import copy from "copy-to-clipboard";
import toast from "react-hot-toast";
import {useBalance} from 'wagmi'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from '../ui/dropdown-menu'
import {Button} from '../ui/button'
import {truncateAddress} from "../../lib/wagmi/utils/truncateAddress";
import {formatNumberWithNumeral} from "../../lib/wagmi/utils/formatNumber";
import logoEth from '../../assets/images/web/eth.svg'
import {IoLogoReact} from "react-icons/io5";

const navigation = [
    {name: 'Home', href: '/', icon: HomeIcon, current: true},
    {
        name: 'Marketplace',
        href: '/marketplace',
        icon: ShoppingBagIcon,
        current: false,
    },
    {
        name: 'Staking',
        href: '/staking',
        icon: IoLogoReact,
        current: false,
    },
    {
        name: 'User Profile',
        href: '/profile',
        icon: UserIcon,
        current: false,
    },
    {
        name: 'News',
        href: '/news',
        icon: NewspaperIcon,
        current: false,
    },
]
const teams = [
    {id: 1, name: 'Twitter', href: '#', initial: 'T', current: false},
    {id: 2, name: 'Follow Us', href: '#', initial: 'F', current: false},
    {
        id: 3,
        name: 'Join Our Community',
        href: '#',
        initial: 'J',
        current: false,
    },
]

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ')
}

function MarketplaceContent() {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const navigator = useNavigate()

    const {logout, currentUser} = useAuth()
    const location = useLocation()
    const navigationFilter = currentUser
        ? navigation
        : navigation.filter((item) => item.href !== '/profile')

    navigationFilter.forEach((item) => {
        item.current = item.href === location.pathname
    })


    const isHiddenFooter = useMemo(() => ["/marketplace/staff", "/marketplace/egg", "/marketplace/accessories" , "/profile","/profile/setting"]?.some(path => location.pathname.startsWith(path)), [location?.pathname])


    const copyToClipboardWithCommand = () => {
        copy(currentUser?.user.connect_wallet_address as string, { format: 'text/plain' })
        toast.success('Copied')
    }

    const result = useBalance({
        address: currentUser?.user.connect_wallet_address as `0x${string }`,
    })

    const handleLogout = () => {
        logout()
        if (
            location.pathname === '/profile' ||
            location.pathname === '/profile/setting'
        ) {
            navigator('/')
        }
    }


    return (
        <div
            className='
            bg-no-repeat
            bg-[length:100%_100%] 
            bg-top
            bg-[#090E13] 
            bg-[url("../src/assets/images/web/bg-market.png")] 
            min-h-[100vh]
        '
        >
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-50 lg:hidden"
                    onClose={setSidebarOpen}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900/80"/>
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative  flex  w-full max-w-xs flex-1">
                                {/* Sidebar component, swap this element with another sidebar if you like */}
                                <div
                                    className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 pb-4 ring-1 ring-white/10">
                                    <div className="absolute top-0 right-4 flex justify-center pt-5">
                                        <button
                                            type="button"
                                            onClick={() =>
                                                setSidebarOpen(false)
                                            }
                                        >
                                            <span className="sr-only">
                                                Close sidebar
                                            </span>
                                            <XMarkIcon
                                                className="h-6 w-6 text-white"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                    <div className="flex h-[90px] shrink-0 items-center bg-[#16202D] px-6">
                                        <img
                                            className="w-[120px] h-[76px]"
                                            src={logoHeader}
                                        />
                                    </div>
                                    <nav className="flex flex-1 flex-col px-6 py-5">
                                        <ul
                                            role="list"
                                            className="flex flex-1 flex-col gap-y-7"
                                        >
                                            <li>
                                                <ul
                                                    role="list"
                                                    className="-mx-2 space-y-1"
                                                >
                                                    {navigationFilter.map(
                                                        (item) => (
                                                            <li key={item.name}>
                                                                <Link
                                                                    to={
                                                                        item.href
                                                                    }
                                                                    className={classNames(
                                                                        item.current
                                                                            ? 'bg-gradient-to-r from-[#ec713a] text-white'
                                                                            : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                                                    )}
                                                                    onClick={() => {
                                                                        setSidebarOpen(
                                                                            false,
                                                                        )
                                                                    }}
                                                                >
                                                                    <item.icon
                                                                        className="h-6 w-6 shrink-0"
                                                                        aria-hidden="true"
                                                                    />
                                                                    {item.name}
                                                                </Link>
                                                            </li>
                                                        ),
                                                    )}
                                                </ul>
                                            </li>
                                            <li>
                                                <div className="text-xs font-semibold leading-6 text-gray-400">
                                                    Support Center
                                                </div>
                                                <ul
                                                    role="list"
                                                    className="-mx-2 mt-2 space-y-1"
                                                >
                                                    {teams.map((team) => (
                                                        <li key={team.name}>
                                                            <a
                                                                href={team.href}
                                                                className={classNames(
                                                                    team.current
                                                                        ? 'bg-[#1c1f25] text-[#ff9345]'
                                                                        : 'text-gray-400 hover:text-white hover:bg-[#1c1f25]',
                                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                                                )}
                                                            >
                                                                <span
                                                                    className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                                                    {
                                                                        team.initial
                                                                    }
                                                                </span>
                                                                <span className="truncate">
                                                                    {team.name}
                                                                </span>
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                            <li className="mt-auto">
                                                <a
                                                    href="/profile/setting"
                                                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                                                >
                                                    <Cog6ToothIcon
                                                        className="h-6 w-6 shrink-0"
                                                        aria-hidden="true"
                                                    />
                                                    Settings
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 pb-4">
                    <div className="flex h-[90px] shrink-0 items-center bg-[#16202D] px-6">
                        <img className="w-[120px] h-[76px]" src={logoHeader}/>
                    </div>
                    <nav className="flex flex-1 flex-col px-6 py-5">
                        <ul
                            role="list"
                            className="flex flex-1 flex-col gap-y-7"
                        >
                            <li>
                                <ul role="list" className="-mx-2 space-y-1">
                                    {navigationFilter.map((item) => (
                                        <li key={item.name}>
                                            <Link
                                                to={item.href}
                                                className={classNames(
                                                    item.current
                                                        ? 'bg-[#1c1f25] text-[#ff9345]'
                                                        : 'text-gray-400 hover:text-[#ff9345] hover:bg-[#1c1f25]',
                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                                )}
                                            >
                                                <item.icon
                                                    className="h-6 w-6 shrink-0"
                                                    aria-hidden="true"
                                                />
                                                {item.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            <li>
                                <div className="text-xs font-semibold leading-6 text-gray-400">
                                    Support Center
                                </div>
                                <ul
                                    role="list"
                                    className="-mx-2 mt-2 space-y-1"
                                >
                                    {teams.map((team) => (
                                        <li key={team.name}>
                                            <a
                                                href={team.href}
                                                className={classNames(
                                                    team.current
                                                        ? 'bg-[#1c1f25] text-[#ff9345]'
                                                        : 'text-gray-400 hover:text-white hover:bg-[#1c1f25]',
                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                                )}
                                            >
                                                <span
                                                    className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                                    {team.initial}
                                                </span>
                                                <span className="truncate">
                                                    {team.name}
                                                </span>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            {
                                !!currentUser?.user && <li className="mt-auto">
                                    <a
                                        onClick={() => navigator('/profile/setting')}
                                        className="group cursor-pointer -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                                    >
                                        <Cog6ToothIcon
                                            className="h-6 w-6 shrink-0"
                                            aria-hidden="true"
                                        />
                                        Settings
                                    </a>
                                </li>
                            }
                        </ul>
                    </nav>
                </div>
            </div>

            <div className="lg:pl-72">
                <div
                    className="sticky top-0 z-40 flex h-[90px] shrink-0 items-center gap-x-4 bg-[#16202D] px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
                    <button
                        type="button"
                        className="-m-2.5 p-2.5 text-white lg:hidden"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
                    </button>

                    {/* Separator */}
                    <div
                        className="h-6 w-px bg-white lg:hidden"
                        aria-hidden="true"
                    />

                    <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                        <div className="relative flex flex-1 items-center">
                            <Breadcrumb/>
                        </div>
                        <div className="flex items-center gap-x-4 lg:gap-x-6">
                            {/* Profile dropdown */}

                            {/* <button
                                type="button"
                                className="inline-flex items-center gap-x-1.5 rounded-full bg-gradient-to-r from-[#ffb444] to-[#eb6f3a] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#eb6f3a] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75" />
                                </svg>
                                Log in
                            </button> */}

                            {!currentUser ? (
                                <div>
                                    <LoginModal/>
                                </div>
                            ) : (
                                <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                       <div className={"flex"}>
                                           <img
                                               src={currentUser?.user?.avatar}
                                               className="w-[39px] h-[39px] rounded-full"
                                               alt={"avatar"}
                                           />
                                           <span className="hidden lg:flex lg:items-center">
                                            <span
                                                className="ml-3 text-sm font-semibold leading-6 text-white"
                                                aria-hidden="true"
                                            >
                                                {currentUser?.user?.nick_name}
                                            </span>
                                            <ChevronDownIcon
                                                className="ml-2 h-6 w-6 text-gray-400"
                                                aria-hidden="true"
                                            />
                                        </span>
                                       </div>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent className="w-[300px] border-none bg-[#10161F] !p-0">
                                        <DropdownMenuLabel className={"p-0"}>
                                            <div className="w-full h-[100px]">
                                                                 <div className="relative">
                                                                     <img alt="background" className="h-[100px]" src={require("../../assets/images/web/news_bg_1.png")} />
                                                                     {/* Black overlay with opacity */}
                                                                     <div className="absolute top-0 right-0 bottom-0 left-0 bg-black bg-opacity-70"></div>
                                                                     {/* Content centered with Flexbox */}
                                                                     <div className="absolute top-0 px-4 right-0 bottom-0 left-0 flex items-center">
                                                                         <div className="flex">
                                                                             <img className="w-10 h-10 rounded-full" src={currentUser?.user.avatar} alt="avatar" />
                                                                             <div className="pl-2">
                                                                                 <p className="text-sm font-semibold text-white">
                                                                                     {currentUser?.user?.nick_name}
                                                                                 </p>
                                                                                     <div className={"flex items-center"}>
                                                                                         <p className="text-[#AFAFAF] text-sm">
                                                                                             {truncateAddress(currentUser?.user?.connect_wallet_address)}
                                                                                         </p>
                                                                                         {
                                                                                             !!currentUser?.user?.connect_wallet_address &&
                                                                                             <DropdownMenuItem className={"hover:!bg-transparent"}>
                                                                                                 <Copy onClick={copyToClipboardWithCommand} className={"text-white hover:text-blue-500 w-3 h-3 ml-2 cursor-pointer"}/>
                                                                                             </DropdownMenuItem>
                                                                                         }
                                                                                     </div>

                                                                             </div>
                                                                         </div>
                                                                     </div>
                                                                 </div>
                                                             </div>
                                                         
                                                        <div className={"flex items-center pt-3 px-3"}>
                                                            { currentUser?.user?.connect_wallet_address ?
                                                                <>
                                                                <p className={"text-white text-sm font-semibold"}>
                                                                    Balance:
                                                                </p>
                                                                <div className={"flex items-center px-2"}>
                                                                    <p className={"text-white font-medium"}>
                                                                        {formatNumberWithNumeral(Number(result?.data?.formatted), 6)}
                                                                    </p>
                                                                    <img src={logoEth} alt={"eth logo"}/>
                                                                </div>
                                                                </>
                                                                :
                                                                <>
                                                                 <Button
                                                                    onClick={() => navigator('/profile/setting')}
                                                                    className="main-button !h-[30px] !rounded !text-sm"
                                                                    >
                                                                    Connect Wallet
                                                                    </Button>
                                                                </>
                                                            }
                                                        </div>
                                        </DropdownMenuLabel>
                                        <DropdownMenuGroup className={"px-1 my-3"}>
                                            <DropdownMenuItem
                                                onClick={() => navigator('/profile')}
                                                className={"hover:!bg-slate-700 cursor-pointer text-white hover:!text-[#ff9345]"}>
                                                    <span className={"font-semibold"}>Your profile</span>
                                            </DropdownMenuItem>
                                        </DropdownMenuGroup>
                                        <DropdownMenuSeparator className={"bg-slate-500"} />

                                        <DropdownMenuItem
                                            onClick={handleLogout}
                                            className={"hover:!bg-transparent pl-3 py-3"}>
                                            <div className={"flex cursor-pointer items-center"}>
                                                <LogOutIcon className="mr-2 text-red-500 h-4 w-4" />
                                                <span className={"text-semibold text-red-500"}> Log out</span>
                                            </div>
                                        </DropdownMenuItem>
                                    </DropdownMenuContent>
                                </DropdownMenu>
                            )}
                        </div>
                    </div>
                </div>

                <main className="pt-10">
                    <div className="px-4 sm:px-6 min-h-[60vh] pb-10 lg:px-8">
                        {/* Your content */}
                        <Outlet/>
                    </div>
                    {/* <div className={cn("flex p-6 justify-between bg-[#070A0D] flex-wrap", isHiddenFooter && "hidden")}>
                        {listFooter?.map((e, idx) => {
                            return (
                                <div
                                    key={`item-footer-${idx}`}
                                    className="w-[48%] sm:w-[23%] mb-4"
                                >
                                    <p className="text-[#5A6E7D] text-lg sm:text-xl font-bold mb-4">
                                        {e?.title}
                                    </p>
                                    {e?.data?.map((item) => {
                                        return (
                                            <div
                                                key={`item-footer-${e?.title}`}
                                                className="mb-1"
                                            >
                                                <a
                                                    href={item?.href}
                                                    className="text-[#304057] text-base  sm:text-lg font-normal hover:text-blue-500"
                                                >
                                                    {item?.title}
                                                </a>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div> */}
                </main>
            </div>
        </div>
    )
}

function MarketplaceLayout() {
    return <MarketplaceContent/>
}

export default MarketplaceLayout
