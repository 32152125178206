import { NotificationError, NotificationSuccess } from '../../src/helpers/alert'
import axios, {
    AxiosRequestConfig,
    InternalAxiosRequestConfig,
    AxiosResponse,
} from 'axios'
import {getDataFromCookie, setDataToCookie} from '../lib/cookie/client'
import {ICurrentUser} from "../lib/context/AuthContext";
import dayjs from "dayjs";

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_END_POINT,
    headers: {
        'Content-Type': 'application/json',
    },
})

const authAxiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_AUTH,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Add a request interceptor
let isRefreshing = false;

axiosClient.interceptors.request.use(async function (config) {
    const tokenData = getDataFromCookie('user') as ICurrentUser;

    if (tokenData && tokenData.token) {
        const tokenExpiration = dayjs.unix(Number(tokenData.expires_in));
        const now = dayjs();

        if (!isRefreshing && tokenExpiration.isBefore(now)) {
            isRefreshing = true;  // Set the flag to indicate a token refresh is in progress
            try {
                const refreshedTokenData = await refreshToken(tokenData.token); // Pass the old token if needed
                setDataToCookie('user', refreshedTokenData?.data);
                config.headers.Authorization = `Bearer ${refreshedTokenData?.data.token}`;
                isRefreshing = false; // Reset the flag after successful refresh
            } catch (error) {
                console.error('Failed to refresh token:', error);
                isRefreshing = false; // Reset the flag if refresh fails
                return Promise.reject(error);
            }
        } else {
            config.headers.Authorization = `Bearer ${tokenData.token}`;
        }
    }

    return config;
}, function (error) {
    isRefreshing = false; // Reset the flag if an error occurs
    return Promise.reject(error);
});
// Add a response interceptor
axiosClient.interceptors.response.use(
    function (response: AxiosResponse) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response.data
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error)
    },
)

export async function get(url: string, config?: AxiosRequestConfig) {
    return await axiosClient
        .get(url, {
            ...config,
        })
        .then((response) => {
           return  response.data ?? response
        })
        .catch((error) => {
            handleError(error)
            throw new Error(`[ Anibar ] ApiService ${error}`)
        })
}

export async function query_params_string(
    url: string,
    params: any,
    config?: AxiosRequestConfig,
) {
    return await axiosClient
        .get(`${url}?${new URLSearchParams(params).toString()}`, {
            ...config,
        })
        .then((response) => response.data)
        .catch((error) => {
            handleError(error)
            throw new Error(`[ Anibar ] ApiService ${error}`)
        })
}

export async function post(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
    isNotification = true,
    setErrorsMessage?: (val: any) => void,
) {
    return await axiosClient
        .post(url, data, {
            ...config,
        })
        .then((response) => {
            if (response.data) {
                isNotification && NotificationSuccess((response as any).message)
            }
            return response.data
        })
        .catch((error) => {
            handleError(error)
            setErrorsMessage &&  setErrorsMessage(error?.response?.data?.message)
            throw new Error(`[ Anibar ] ApiService ${error}`)
        })
}

export async function put(url: string, data: any, config?: AxiosRequestConfig) {
    return await axiosClient
        .put(url, data, {
            ...config,
        })
        .then((response) => response.data)
        .catch((error) => {
            handleError(error)
            throw new Error(`[ Anibar ] ApiService ${error}`)
        })
}

export async function del(url: string, data: any, config?: AxiosRequestConfig) {
    return await axiosClient
        .delete(url, data)
        .then((response) => response.data)
        .catch((error) => {
            handleError(error)
            throw new Error(`[ Anibar ] ApiService ${error}`)
        })
}

// function logout() {
//   store.dispatch(authActions.logout())
// }

function handleError(error: any) {
    console.log('error', error)
    let message = error?.message || error?.payload?.message
    if (error?.response && error?.response?.data) {
        message =
            error?.response?.data?.message[0].message ||
            error?.response?.data?.message
    }
    if (error.response.status === 401) {
        NotificationError(message || 'Your token has expired')
        // logout();
        return
    }

    if (message !== undefined) {
        NotificationError(message || 'Một lỗi đã xảy ra. Vui lòng thử lại sau.')
    }
}

export const refreshToken = async (oldToken) => {
    return await authAxiosClient.get("/api/refresh-token", {
        headers: {
            Authorization: `Bearer ${oldToken}`
        }
    }).then(res => res.data)
        .catch(error => {
            throw new Error('Failed to refresh token');
        });
};