import React, { useState } from 'react'
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'
import LogoHeader from 'src/assets/images/webp/logo-header.webp'
import open from 'src/assets/images/webp/open.webp'
import close from 'src/assets/images/webp/close.webp'
import { useNavigate } from 'react-router-dom'
type Props = {}

const Navbar = (props: Props) => {
    const [nav, setNav] = useState(false)
    const navigate = useNavigate()

    const handleNav = () => {
        setNav(!nav)
    }

    const handleClickTop = (event: React.MouseEvent<HTMLElement>) => {
        navigate('/', {
            state: { scrollTo: 'scrollToTop' },
        })
    }

    const handleClickMarketplace = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        navigate('/', {
            state: { scrollTo: '.scrollToMarketplace' },
        })
        setNav(false)
    }

    const handleClickGames = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        navigate('/', {
            state: { scrollTo: '.scrollToGame' },
        })
        setNav(false)
    }

    const handleClickLore = () => {
        console.log('click to lore.')
        setNav(false)
    }
    const handleClickANI = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        navigate('/', {
            state: { scrollTo: '.scrollToAni' },
        })
        setNav(false)
    }
    const handleClickNews = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        navigate('/', {
            state: { scrollTo: '.scrollToNew' },
        })
        setNav(false)
    }
    const handleClickMore = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        navigate('/', {
            state: { scrollTo: '.scrollToMore' },
        })
        setNav(false)
    }

    return (
        <div className="fixed top-0 left-0 w-full z-20 h-48">
            <div className="font-rowdies text-white flex justify-between items-center h-24 max-w-[1024px] mx-auto px-4 ">
                <div className='hidden lg:flex w-full h-[66px] flex items-center bg-no-repeat bg-[length:100%_100%] bg-center bg-[url("../src/assets/images/webp/Rectangle106.webp")]'>
                    <div className="w-1/5 h-[40px] px-5 transform transition hover:scale-110">
                        <img
                            className="w-[90px] h-full cursor-pointer"
                            src={LogoHeader}
                            alt="logo"
                            onClick={handleClickTop}
                        />
                    </div>
                    <ul className="flex flex-row w-4/5 justify-evenly text-[20px]">
                        <li className="p-4 transform transition hover:scale-110">
                            <a href="#" onClick={handleClickMarketplace}>
                                Marketplace
                            </a>
                        </li>
                        <li className="p-4 transform transition hover:scale-110">
                            <a href="#" onClick={handleClickGames}>
                                Games
                            </a>
                        </li>
                        <li className="p-4 transform transition hover:scale-110">
                            <a href="#">Lore</a>
                        </li>
                        <li className="p-4 transform transition hover:scale-110">
                            <a href="#" onClick={handleClickANI}>
                                ANI
                            </a>
                        </li>
                        <li className="p-4 transform transition hover:scale-110">
                            <a href="#" onClick={handleClickNews}>
                                News
                            </a>
                        </li>
                        <li className="p-4 transform transition hover:scale-110">
                            <a href="#" onClick={handleClickMore}>
                                More
                            </a>
                        </li>
                    </ul>
                </div>
                <div onClick={handleNav} className="block lg:hidden fixed z-50">
                    {nav ? (
                        <button className="w-[50px] h-[42px] bg-[#BF39A1] flex justify-center items-center rounded-[40px] drop-shadow-[0_10px_#A30F70]">
                            <img
                                className="w-full h-full"
                                src={close}
                                alt="close"
                            />
                        </button>
                    ) : (
                        <button className="w-[50px] h-[42px] bg-[#BF39A1] flex justify-center items-center rounded-[40px] drop-shadow-[0_10px_#A30F70]">
                            <img className="w-1/2" src={open} alt="open" />
                        </button>
                    )}
                </div>
                <div
                    className={
                        nav
                            ? 'fixed left-0 top-20 w-[60%] z-50 ease-in-out duration-500'
                            : 'fixed left-[-100%]'
                    }
                >
                    <ul className="uppercase py-8 px-5 gap-7 flex flex-col">
                        <li>
                            <button
                                className="w-[250px] h-[60px] text-[26px] bg-[#BF39A1] rounded-[40px] drop-shadow-[0_10px_#A30F70]"
                                onClick={handleClickMarketplace}
                            >
                                Marketplace
                            </button>
                        </li>
                        <li>
                            <button
                                className="w-[250px] h-[60px] text-[26px] bg-[#BF39A1] rounded-[40px] drop-shadow-[0_10px_#A30F70]"
                                onClick={handleClickGames}
                            >
                                Games
                            </button>
                        </li>
                        <li>
                            <button
                                className="w-[250px] h-[60px] text-[26px] bg-[#BF39A1] rounded-[40px] drop-shadow-[0_10px_#A30F70]"
                                onClick={handleClickLore}
                            >
                                Lore
                            </button>
                        </li>
                        <li>
                            <button
                                className="w-[250px] h-[60px] text-[26px] bg-[#BF39A1] rounded-[40px] drop-shadow-[0_10px_#A30F70]"
                                onClick={handleClickANI}
                            >
                                ANI
                            </button>
                        </li>
                        <li>
                            <button
                                className="w-[250px] h-[60px] text-[26px] bg-[#BF39A1] rounded-[40px] drop-shadow-[0_10px_#A30F70]"
                                onClick={handleClickNews}
                            >
                                News
                            </button>
                        </li>
                        <li>
                            <button
                                className="w-[250px] h-[60px] text-[26px] bg-[#BF39A1] rounded-[40px] drop-shadow-[0_10px_#A30F70]"
                                onClick={handleClickMore}
                            >
                                More
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        // <div className='text-white flex justify-between items-center h-24 max-w-[1024px] mx-auto px-4'>
        //   <h1 className='w-full text-3xl font-bold text-[#00df9a]'>REACT.</h1>
        //   <ul className='hidden md:flex'>
        //     <li className='p-4'>Home</li>
        //     <li className='p-4'>Company</li>
        //     <li className='p-4'>Resources</li>
        //     <li className='p-4'>About</li>
        //     <li className='p-4'>Contact</li>
        //   </ul>
        //   <div onClick={handleNav} className='block md:hidden'>
        //     {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        //   </div>
        //   <div className={nav ? 'fixed left-0 top-0 w-[60%] border-r-gray-900 h-full bg-[#000300] ease-in-out duration-500' : 'fixed left-[-100%]'}>
        //     <h1 className='w-full text-3xl font-bold text-[#00df9a] m-4'>REACT</h1>
        //     <ul className='uppercase p-4'>
        //       <li className='p-4 border-b border-gray-600'>Home</li>
        //       <li className='p-4 border-b border-gray-600'>Company</li>
        //       <li className='p-4 border-b border-gray-600'>Resources</li>
        //       <li className='p-4 border-b border-gray-600'>About</li>
        //       <li className='p-4 border-b border-gray-600'>Contact</li>
        //     </ul>
        //   </div>
        // </div>
    )
}

export default Navbar
