export const Rate = [
    'Rare',
    'Supper rate',
    'Epic',
    'Mythical',
    'Legendary',
    'Immortal',
]
export const Class = ['Cat', 'Dog', 'Rabbit', 'Epic', 'Bear', 'Dragon']

export const RateBg: {
    [key: string]: string
} = {
    0: 'linear-gradient(90deg, #555B5F 0%, #393C43 102.04%)',
    1: 'linear-gradient(90deg, #6F9112 0%, #1F4905 102.04%)',
    2: 'linear-gradient(90deg, #2A7DCA 0%, #0F477A 102.04%)',
    3: 'linear-gradient(90deg, #9422C9 0%, #4D0C7F 102.04%)',
    4: 'linear-gradient(90deg, #B6651A 0%, #834206 102.04%)',
    5: 'linear-gradient(90deg, #912912 0%, #721205 102.04%)',
}

export const BgNft: {
    [key: string]: string
} = {
    0: 'linear-gradient(180deg, #202225 0%, #313237 100%)',
    1: 'linear-gradient(180deg, #1D2025 0%, #2A3125 100%)',
    2: 'linear-gradient(180deg, #121720 0%, #212F44 100%)',
    3: 'linear-gradient(180deg, #18111C 0%, #391E4E 100%)',
    4: 'linear-gradient(180deg, #201007 0%, #3A291F 100%)',
    5: 'linear-gradient(180deg, #140B0B 0%, #391414 100%)',
}

export const borderCard: {
    [key: string]: string
} = {
    0: '#39393B',
    1: '#37362F',
    2: '#153649',
    3: '#3C2750',
    4: '#563823',
    5: '#4C0A0A',
}
