import React, {
    createContext,
    useContext,
    useState,
    ReactNode,
    ReactElement,
    Dispatch,
    SetStateAction,
} from 'react'
import ShadModal from 'src/components/ui/ShadModal'

interface ModalContextType {
    openModal: (content: ReactNode) => void
    closeModal: () => void
}

const ModalContext = createContext<ModalContextType | undefined>(undefined)

export const useModalContext = (): ModalContextType => {
    const context = useContext(ModalContext)
    if (!context) {
        throw new Error('useModalContext must be used within a ModalProvider')
    }
    return context
}

export const ModalProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [modalContent, setModalContent] = useState<ReactNode>(null)

    const openModal = (content: ReactNode) => {
        setModalContent(content)
        setIsOpen(true)
    }

    const closeModal = () => {
        setIsOpen(false)
        setTimeout(() => setModalContent(null), 300) // Optional: Clear modal content
    }

    const renderModal = (): ReactElement | null => {
        if (!isOpen || !modalContent) return null
        return (
            <ShadModal
                isOpen={isOpen}
                onOpen={setIsOpen as Dispatch<SetStateAction<boolean>>}
            >
                {modalContent}
            </ShadModal>
        )
    }

    return (
        <ModalContext.Provider value={{ openModal, closeModal }}>
            {children}
            {renderModal()}
        </ModalContext.Provider>
    )
}
