import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import EmptyState from 'src/components/Common/EmptyState'
import { Loading } from 'src/components/ui/Loading'
import { formatNumberWithNumeral } from 'src/lib/wagmi/utils/formatNumber'
import { truncateAddress } from 'src/lib/wagmi/utils/truncateAddress'
import { fetcher } from 'src/utils/fetcher'
import useSWR from 'swr'
import logoEth from '../../../assets/images/web/eth.svg'
import ButtonBuyNft, { StatusNft } from '../components/DetailNft/ButtonBuyNft'
import BuyHistory from '../components/DetailNft/BuyHistory'
import { Class, Rate, borderCard } from '../configs'
import { NftListingResponse } from '../interfaces'
import { useGetListing } from '../services/marketplace.service'
import {useGetPriceEth} from "../../../lib/hooks/usePriceEth";

type Props = {}

const MarketplaceDetailPage = (props: Props) => {
    const priceEth = useGetPriceEth()
    useState<NftListingResponse | null>(null)
    const params = useParams()
    const {
        data: nftDetailListing,
        isLoading,
        mutate: triggerListing,
    } = useSWR(
        `${process.env.REACT_APP_API}/v1/marketplace?nft-id=${params?.id}&status=listing`,
        fetcher,
        {
            refreshInterval: 10000,
        },
    )

    const { data: nftDetailNotListed, mutate: triggerNotListing } = useSWR(
        `${process.env.REACT_APP_API}/v1/marketplace/nft-detail/${params?.id}`,
        fetcher,
        {
            refreshInterval: 10000,
        },
    )

    const nftDetailData = useMemo(
        () =>
            nftDetailNotListed?.data?.status === StatusNft.ACTIVE ||
            nftDetailNotListed?.data?.status === StatusNft.PLAYING
                ? {
                      nft: {
                          ...nftDetailNotListed?.data,
                      },
                      seller_avatar: nftDetailNotListed?.data?.owner_avatar,
                      seller_name: nftDetailNotListed?.data?.owner_name,
                      owner_address: nftDetailNotListed?.data?.owner_address,
                  }
                : nftDetailListing?.data?.[0],

        [nftDetailListing?.data, nftDetailNotListed?.data],
    ) as NftListingResponse

    const { dataNft } = useGetListing(nftDetailData?.listing_index, 3000)

    const listIcon = [
        {
            icon: require('../../../assets/images/web/Icon140.png'),
            name: 'Movespeed',
            value: nftDetailData?.nft.move_speed ?? 0,
        },
        {
            icon: require('../../../assets/images/web/Icon141.png'),
            name: 'Order speed',
            value: nftDetailData?.nft?.order_speed ?? 0,
        },
        {
            icon: require('../../../assets/images/web/Icon142.png'),
            name: 'Working speed',
            value: nftDetailData?.nft?.working_speed ?? 0,
        },
        {
            icon: require('../../../assets/images/web/Icon143.png'),
            name: 'Income',
            value: nftDetailData?.nft?.income ?? 0,
        },
        {
            icon: require('../../../assets/images/web/Icon144.png'),
            name: 'Lucky',
            value: `${nftDetailData?.nft?.lucky?.toFixed(2) ?? 0}%`,
        },
    ]

    const priceNft = useMemo(
        () => dataNft?.priceFormat || nftDetailData?.price,
        [dataNft?.priceFormat, nftDetailData?.price],
    )

    return (
        <div className="text-white flex flex-col sm:px-4 w-full">
            {isLoading && !nftDetailData ? (
                <Loading />
            ) : !nftDetailData ? (
                <EmptyState description=" This nft does not exist or has had its listing canceled" />
            ) : (
                <div className="flex flex-wrap gap-3 w-full">
                    <div className="w-full md:w-[60%] flex flex-col md:gap-8 gap-3">
                        <div
                            className='w-full
                    bg-[url("../src/assets/images/web/bg-banner-homepage.png")]  bg-no-repeat
                    flex rounded-[20px] flex-col h-[400px] relative justify-center items-center bg-cover'
                        >
                            <img
                                src={nftDetailData?.nft?.image ?? ''}
                                alt=""
                                className="w-[240px] h-[240px] absolute"
                            />
                        </div>

                        <div className="w-full flex flex-col gap-2 sm:gap-5 bg-[#10161F] p-2 sm:p-5 rounded-[20px]">
                            <div className="text-2xl font-bold text-[#FF9345]">
                                Traits
                            </div>
                            <div className="flex gap-3 flex-wrap">
                                {listIcon.map((item, index) => (
                                    <div
                                        key={index}
                                        className="flex flex-row w-[45%] gap-2 items-center"
                                    >
                                        <div className="w-[54px] sm:w-[80px]">
                                            <img
                                                src={item.icon}
                                                className="w-full"
                                                alt="icon-working"
                                            />
                                        </div>
                                        <div className="flex flex-col">
                                            <div className="text-[#60708A] text-sm sm:text-[16px]">
                                                {item.name}
                                            </div>
                                            <div className="text-sm sm:text-[16px]">
                                                {item.value}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:h-[400px] flex flex-col gap-3 md:w-[38%] bg-[#10161F] p-5 rounded-[20px]">
                        <div className="w-full grid grid-cols-2 gap-1">
                            <div className="flex flex-col gap-2">
                                <div className="text-[24px]">
                                    {nftDetailData?.nft?.name}
                                </div>
                                <div
                                    className="
                          w-[86px]
                          bg-[#1C2431]
                          text-[14px]
                          text-[#AFAFAF]
                          flex
                          h-[24px]
                          items-center
                          rounded-[5px]
                          px-2
                          !text-left
                      "
                                >
                                    #{nftDetailData?.nft?.nft_id}
                                </div>
                            </div>
                            <div className="flex flex-col gap-2 items-end">
                                <div className="flex w-[150px] flex-row justify-between gap-3">
                                    <div className="text-[14px] text-[#AFAFAF]">
                                        Class
                                    </div>
                                    <div
                                        className="
                              px-2
                              text-[14px]
                              flex
                              w-fit
                              items-center
                              justify-center
                              h-[24px]
                              rounded-[5px]
                          "
                                        style={{
                                            background:
                                                borderCard[
                                                    nftDetailData?.nft?.class
                                                ],
                                        }}
                                    >
                                        {Class[nftDetailData?.nft?.class]}
                                    </div>
                                </div>
                                <div className="flex w-[150px] flex-row justify-between gap-3">
                                    <div className="text-[14px] text-[#AFAFAF]">
                                        Rarity
                                    </div>
                                    <div
                                        className='
                              w-fit
                              px-2
                              bg-[url("../src/assets/images/web/super-rare.png")]
                              bg-[length:100%_100%]
                              bg-no-repeat
                              bg-center
                              text-[14px]
                              flex
                              items-center
                              justify-center
                              h-[24px]
                          '
                                    >
                                        {Rate[nftDetailData?.nft?.rare]}
                                    </div>
                                </div>
                                <div className="flex w-[150px] flex-row justify-between gap-3">
                                    <div className="text-[14px] text-[#AFAFAF]">
                                        Level
                                    </div>
                                    <div
                                        className="
                              bg-[#BC7330]
                              text-[14px]
                              min-w-[48px]
                              flex
                              w-fit
                              px-2
                              items-center
                              justify-center
                              h-[24px]
                              rounded-[5px]
                          "
                                    >
                                        {nftDetailData?.nft?.level}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex flex-col gap-1">
                            <div className="text-[16px] text-[#60708A]">
                                Owner
                            </div>
                            <div className="flex flex-row gap-3 items-center">
                                <div>
                                    <img
                                        src={nftDetailData?.seller_avatar}
                                        alt=""
                                        className="w-[39px] h-[39px] rounded-full"
                                    />
                                </div>
                                <div className="flex flex-col gap-1">
                                    <div className="text-[16px] font-bold">
                                        {nftDetailData?.seller_name}
                                    </div>
                                    {!!nftDetailData?.owner_address && (
                                        <div className="text-[14px]">
                                            (
                                            {truncateAddress(
                                                nftDetailData?.owner_address,
                                            )}
                                            )
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {!!nftDetailData?.price &&
                            nftDetailData.nft?.status === StatusNft.LISTING && (
                                <div className="w-full flex flex-col gap-1">
                                    <div className="text-[16px] text-[#60708A]">
                                        Current price
                                    </div>
                                    <div className="flex flex-row gap-3 items-center">
                                        <div>
                                            <img
                                                src={logoEth}
                                                className="w-[24px] h-[24px]"
                                                alt="currency"
                                            />
                                        </div>
                                        <div className="text-[22px] font-bold">
                                            {priceNft}
                                        </div>
                                        <div className="mt-[-4px]">
                                            <sub className="text-[14px]  text-[#AFAFAF]">
                                                $
                                                {formatNumberWithNumeral(
                                                    nftDetailData?.price * priceEth,
                                                    2,
                                                )}
                                            </sub>
                                        </div>
                                    </div>
                                </div>
                            )}
                        <div className="w-full mt-8 flex flex-col">
                            <ButtonBuyNft
                                timeInterval={3000}
                                onDoneCancel={() => {
                                    // setStatusNft(StatusNft.ACTIVE)
                                    triggerNotListing()
                                }}
                                onDoneCreate={() => {
                                    // setStatusNft(StatusNft.LISTING)
                                    triggerListing()
                                }}
                                classNameButtonLogin={'!w-full h-[48px]'}
                                idNft={nftDetailData?.nft?.nft_id}
                                ownerAddress={nftDetailData?.owner_address}
                                idListing={nftDetailData?.listing_index}
                            />
                        </div>
                    </div>
                    <BuyHistory />
                </div>
            )}
        </div>
    )
}

export default MarketplaceDetailPage
