import Lottie from 'lottie-react'
import EmptyStateJson from '../../lib/contants/animations/EmptyState.json'

const EmptyState = ({ description }: { description?: string }) => {
    return (
        <div className="py-10">
            <div className="w-[200px] mx-auto">
                <Lottie animationData={EmptyStateJson} loop={true} />
            </div>
            <p className="text-white -mt-10 text-xl font-semibold text-center pt-10">
                Nothing Found
            </p>
            {!!description && (
                <p className="text-[#899DCD] text-center">{description}</p>
            )}
        </div>
    )
}

export default EmptyState
