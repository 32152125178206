import styled from '@emotion/styled'
import { Slider } from '@mui/material'

const CustomSlider = styled(Slider)(({ theme, value, max }) => ({
    // Add your custom styles here
    '& .MuiSlider-thumb': {
        height: '24px',
        width: '24px',
        backgroundColor: '#FF8C00',
        boxShadow: 'none',
    },
    '& .MuiSlider-track': {
        backgroundColor: '#FF8C00',
        opacity: 1,
        border: 'none',
    },
    '& .MuiSlider-rail': {
        backgroundColor: '#1C1F25',
    },
    '& .MuiSlider-mark': {
        height: '24px',
        width: '24px',
        backgroundColor: '#1C1F25', // Default mark color
        borderRadius: '100%',
        marginLeft: '-12px', // Adjust this value to center the mark
        '&.MuiSlider-markActive': {
            backgroundColor: '#FF8C00', // Mark color when active (thumb has passed or reached)
            opacity: 1,
        },
    },
    '& .MuiSlider-markLabel': {
        color: 'white',
    },
}))

export default CustomSlider
