import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import EmptyState from 'src/components/Common/EmptyState'
import { cn } from 'src/utils/common'
import { fetcher } from 'src/utils/fetcher'
import { genPramsCheckNull } from 'src/utils/genPramsCheckNull'
import useSWR from 'swr'
import OrderByFilter from '../components/FilterOrderByType'
import FormSearch from '../components/FormSearch'
import ListNft from '../components/ListNft'
import ModalFilter from '../components/ModalFilter'
import Search from '../components/Search'
import StatsListNft from '../components/StatsListNft'
import { NftListingResponse } from '../interfaces'
import ComingSoon from 'src/components/Common/ComingSoon'

const ListMarketplacePage = () => {
    const location = useLocation()
    const [filterClass, setFilterClass] = useState(0)
    const [filterRarity, setFilterRarity] = useState(0)
    const [filterLevel, setFilterLevel] = useState(0)
    const [valueInputSearch, setValueInputSearch] = useState('')
    const [listNft, setListNft] = useState<NftListingResponse[]>([])
    const [page, setPage] = useState(1)
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [filterPrice, setFilterPrice] = useState({
        minPrice: '0',
        maxPrice: '0',
    })
    const [orderType, setOrderType] = useState({
        label: 'Highest Price',
        value: 'desc',
        type: 'price'
    })

    const typeNft = useMemo(
        () =>
            location?.pathname.includes('egg')
                ? 'egg'
                : location?.pathname.includes('staff')
                ? 'staff'
                : 'accessory',
        [location?.pathname],
    )

    useEffect(() => {
        if (page !== 1) {
            setPage(1)
        }
    }, [
        filterClass,
        filterRarity,
        filterLevel,
        filterPrice.minPrice,
        filterPrice.maxPrice,
        orderType,
    ])

    const onReset = () => {
        setFilterClass(0)
        setFilterLevel(0)
        setFilterPrice({
            minPrice: '0',
            maxPrice: '0',
        })
        setFilterRarity(0)
        setPage(1)
    }

    const handleSearchChange = (e: { target: { value: any } }) => {
        const newValue = e.target.value
        setValueInputSearch(newValue)
        setPage(1)
    }

    const { data } = useSWR(
        genPramsCheckNull(
            {
                rarity: filterRarity,
                level: filterLevel,
                class: filterClass,
                'min-price': filterPrice.minPrice,
                'max-price': filterPrice.maxPrice,
                'order-type': orderType.value,
                keyword: valueInputSearch,
                page: page,
                limit: 20,
                'sort-by': orderType?.type,
                status: 'listing',
                'market-type': 'pet',
            },
            `${process.env.REACT_APP_API}/v1/marketplace`,
        ),
        fetcher,
    )

    const listNftListingData = (data?.data ?? []) as NftListingResponse[]

    useEffect(() => {
        if (JSON.stringify(listNftListingData) !== JSON.stringify(listNft)) {
            if (page === 1) {
                setListNft(listNftListingData)
            } else {
                setListNft((prev) => [...prev, ...listNftListingData])
            }
        }
    }, [JSON.stringify(listNftListingData), valueInputSearch])

    if (typeNft === 'accessory' || typeNft === 'egg') return <ComingSoon />

    return (
        <div className="text-white">
            <div className="flex flex-col mb-5 gap-5">
                <div className="w-full flex flex-wrap md:justify-between justify-center gap-5">
                    <div className="md:max-w-[596px] w-full">
                        <h1 className="text-[32px] font-500">
                            {typeNft?.toUpperCase()}
                        </h1>
                        <p className="text-sm text-[#959595]">
                            {typeNft?.toUpperCase()} are cute and formidable
                            creatures that can be battled, bred, collected, and
                            even used to earn resources $ collectible.
                        </p>
                    </div>

                    <StatsListNft />
                </div>

                <div className="w-full flex flex-wrap justify-between gap-5">
                    <div className="sm:max-w-[400px] w-full">
                        <nav className="flex sm:gap-6 gap-3" aria-label="Tabs">
                            <ModalFilter
                                setFilterClass={setFilterClass}
                                setFilterLevel={setFilterLevel}
                                setFilterPrice={setFilterPrice}
                                setFilterRarity={setFilterRarity}
                                onReset={onReset}
                                filterClass={filterClass}
                                filterLevel={filterLevel}
                                filterPrice={filterPrice}
                                filterRarity={filterRarity}
                            />
                            <p
                                className={cn(
                                    'shrink-0 rounded-lg p-2 text-sm font-medium text-gray-500 ',
                                    filterClass && 'text-[#ec713a]',
                                )}
                            >
                                Genre
                            </p>

                            <p
                                className={cn(
                                    'shrink-0 rounded-lg p-2 text-sm font-medium text-gray-500 ',
                                    filterRarity && 'text-[#ec713a]',
                                )}
                            >
                                Rarity
                            </p>

                            <p
                                className={cn(
                                    'shrink-0 rounded-lg  p-2 text-sm font-medium text-gray-500',
                                    filterLevel && 'text-[#ec713a]',
                                )}
                            >
                                Level
                            </p>

                            <p
                                className={cn(
                                    'shrink-0 rounded-lg  p-2 text-sm font-medium text-gray-500',
                                    Number(filterPrice?.maxPrice) ||
                                        Number(filterPrice?.minPrice)
                                        ? 'text-[#ec713a]'
                                        : '',
                                )}
                            >
                                Price range
                            </p>
                        </nav>
                    </div>

                    <div className="flex gap-4 items-center">
                        <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                            <FormSearch
                                onChange={handleSearchChange}
                                value={valueInputSearch}
                            />
                        </div>
                        <div className="flex flex-row justify-center items-center">
                            <OrderByFilter
                                orderType={orderType}
                                setOrderType={setOrderType}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full font-bold text-[20px]">
                {listNft?.length ?? 0} {typeNft.toUpperCase()}
            </div>
            {listNft?.length > 0 ? (
                <ListNft
                    listNft={listNft}
                    currentListNftListingData={listNftListingData}
                    page={page}
                    setPage={setPage}
                />
            ) : (
                <EmptyState />
            )}

            <Search sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        </div>
    )
}

export default ListMarketplacePage
