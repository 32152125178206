import {toast} from 'react-toastify'

export const NotificationInfo = (message: string) => {
    toast.info(message, {
        autoClose: 5000,
        position: 'top-right',
    })
}

export const NotificationSuccess = (message: string) => {
    toast.success(message, {
        autoClose: 5000,
        position: 'top-right',
    })
}

export const NotificationError = (message: string) => {
    console.log("message", message)
    toast.error(message, {
        autoClose: 5000,
        position: 'top-right',
    })
}
