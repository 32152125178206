import {useEffect, useRef, useState} from 'react'
import {Area, AreaChart} from 'recharts'
import {formatNumberWithNumeral} from 'src/lib/wagmi/utils/formatNumber'
import {fetcher} from 'src/utils/fetcher'
import useSWR from 'swr'
import Dollar from '../../../assets/images/dollar.svg'
import {IChart, MarketStatsOverview} from '../interfaces'
import {cn} from 'src/utils/common'
import {genPramsCheckNull} from 'src/utils/genPramsCheckNull'
import {atom, useAtom} from "jotai";
import ethereumLogo  from '../../../assets/images/web/ethereum.svg'
import kitti from '../../../assets/images/web/kitti.svg'
import userIcon from '../../../assets/images/web/user.svg'
import ethLogo from '../../../assets/images/web/eth.svg'

interface IFilterOverStats {
    title: string
    value: number
    type: string
}

const listFilterTime: IFilterOverStats[] = [
    {
        title: '24H',
        value: 24,
        type: 'hour',
    },
    {
        title: '7 days',
        value: 7,
        type: 'day',
    },
    {
        title: '30 days',
        value: 30,
        type: 'day',
    },
]

const marketStatsOverAtom = atom<MarketStatsOverview | null>(null)
const OverallStats = () => {
    const [marketStatsOverData, setMarketStatsOverData] = useAtom(marketStatsOverAtom)
    const chartRef = useRef(null)
    const [chartWidth, setChartWidth] = useState(null)
    const [statsTime, setStatsTime] = useState<IFilterOverStats>(
        listFilterTime[1],
    )

    const {data: marketStatsOver} = useSWR(
        genPramsCheckNull(
            {
                'interval-type': statsTime.type,
                duration: statsTime.value,
            },
            `${process.env.REACT_APP_API}/v1/marketplace/overall-stats`,
        ),
        fetcher,
    )

    useEffect(() => {
        if (marketStatsOver?.data) {
            setMarketStatsOverData(marketStatsOver?.data)
        }
    }, [marketStatsOver?.data]);


    useEffect(() => {
        function handleResize() {
            if (chartRef.current) {
                setChartWidth((chartRef.current as any).offsetWidth)
            }
        }

        window.addEventListener('resize', handleResize)

        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <div className="bg-[#10161F] p-5 mb-5 rounded-[20px]">
            <div className="flex flex-wrap justify-between">
                <h2 className="text-[24px] font-bold mb-5">Overall stats</h2>
                <div className="flex gap-2 mb-5">
                    {listFilterTime?.map((e) => {
                        const isActive = statsTime?.value === e.value
                        return (
                            <button
                                onClick={() => setStatsTime(e)}
                                key={`filter-${e?.title}`}
                                className={cn(
                                    'btn btn-sm bg-[#1C1F25] text-[#60708A]  border-none',
                                    isActive && 'text-[#FF9345]',
                                )}
                            >
                                {e?.title}
                            </button>
                        )
                    })}
                </div>
            </div>

            <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                <div className="m-3 flex-row">
                    <div className="flex items-center gap-5">
                        <img src={userIcon} alt="/"/>
                        <div>
                            <p className="text-[#737d8f] font-[700] ">
                                Total sales
                            </p>
                            <h2 className="text-white text-xl font-bold">
                                {formatNumberWithNumeral(
                                    marketStatsOverData?.overall_sale
                                        ?.total_sale ?? 0,
                                )}
                            </h2>
                        </div>
                    </div>
                    <div ref={chartRef} className=" h-[130px] mt-5">
                        <ChartOver
                            type="default"
                            dataChart={marketStatsOverData?.overall_sale?.chart || []}
                            chartWidth={chartWidth ?? 0}
                        />
                    </div>
                </div>

                <div className=" m-3 flex-row">
                    <div className="flex items-center gap-5">
                        <img src={ethereumLogo} alt="/"/>
                        <div>
                            <p className="text-[#737d8f] font-[700] ">
                                Total volume
                            </p>
                           <div className={"flex items-center"}>
                               <h2 className="text-white text-xl pr-1 font-bold">
                                   {formatNumberWithNumeral(
                                       marketStatsOverData?.overall_volume
                                           ?.total_volume ?? 0,
                                   )}
                               </h2>
                               <img src={ethLogo} alt={"logo eth"}/>
                           </div>
                        </div>
                    </div>
                    <div className="h-[130px] mt-5">
                        <ChartOver
                            type="volume"
                            stroke="#f25f33"
                            dataChart={
                                marketStatsOverData?.overall_volume?.chart || []
                            }
                            chartWidth={chartWidth ?? 0}
                        />
                    </div>
                </div>

                <div className="m-3 flex-row">
                    <div className="flex items-center gap-5">
                        <img src={kitti} alt="/"/>
                        <div>
                            <p className="text-[#737d8f] font-[700] ">
                                Staff sold
                            </p>
                            <h2 className="text-white text-xl font-bold">
                                {formatNumberWithNumeral(
                                    marketStatsOverData?.overall_sold
                                        .total_sold ?? 0,
                                )}
                            </h2>
                        </div>
                    </div>
                    <div className="h-[130px] mt-5">
                        <ChartOver
                            stroke="#8f62eb"
                            dataChart={marketStatsOverData?.overall_sold?.chart || []}
                            chartWidth={chartWidth ?? 0}
                            type="sold"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const ChartOver = ({
                       dataChart,
                       chartWidth,
                       stroke,
                       type,
                   }: {
    dataChart: IChart[]
    chartWidth: number
    stroke?: string
    type: 'default' | 'volume' | 'sold'
}) => {
    const stopColor =
        type === 'default'
            ? 'rgb(15,42,39)'
            : type === 'volume'
                ? 'rgb(43,38,26)'
                : 'rgb(30,29,47)'

    const gradientId = `colorUv-${type}`

    return (
        <AreaChart
            id={type}
            width={chartWidth ?? 200}
            height={150}
            data={dataChart}
        >
            <defs>
                <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={stopColor} stopOpacity={0.8}/>
                    <stop offset="95%" stopColor={stopColor} stopOpacity={0}/>
                </linearGradient>
            </defs>
            <Area
                type="monotone"
                dataKey="value"
                stroke={stroke ?? '#AFDB1A'}
                fillOpacity={1}
                fill={`url(#${gradientId})`}
                activeDot={{
                    stroke: stroke ?? '#AFDB1A',
                    strokeWidth: 2,
                    r: 5,
                }}
            />
        </AreaChart>
    )
}

export default OverallStats
