import {useCallback, useState} from 'react'
import {get, post, put} from '../../../api/axiosClient'
import {Input} from '../../../components/ui/input'
import {Button} from '../../../components/ui/button'
import {StepForgotPassword} from '../interfaces'
import {toast} from "react-toastify";

const StepForgotPasswordComponent = ({onBack}: { onBack: () => void }) => {
    const [stepForgotPassword, setStepForgotPassword] = useState(
        StepForgotPassword.VERIFY_EMAIL,
    )
    const [isLoading, setIsLoading] = useState(false)
    const [code, setCode] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [errors, setErrors] = useState({
        email: '',
        code: '',
        password: '',
        confirmPassword: '',
    })

    const handleResetPasswordVerifyEmail = useCallback(async () => {
        // Validation
        if (!email || !/^\S+@\S+\.\S+$/.test(email)) {
            setErrors({
                ...errors,
                email: 'Please enter a valid email address.',
            })
            return
        }
        try {
            setIsLoading(true)
            await post(
                `${process.env.REACT_APP_API_AUTH}/api/password-reset/verify-email`,
                {
                    email: email,
                },
            )

            setStepForgotPassword(StepForgotPassword.VERIFY_CODE)
        } catch (error) {
            console.error('Error Resend code verify email', error)
        } finally {
            setIsLoading(false)
        }
    }, [email])

    const handleVerifyCodeReset = useCallback(async () => {
        // Validation
        if (!code) {
            setErrors({
                ...errors,
                code: 'Please enter the verification code.',
            })
            return
        }
        try {
            setIsLoading(true)
            await get(
                `${process.env.REACT_APP_API_AUTH}/api/password-reset/verify-code?email=${email}&code=${code}`,
            ).then((res) => res)

            setStepForgotPassword(StepForgotPassword.RESET_PASSWORD)
        } catch (error) {
            console.error('Error Resend code verify email', error)
        } finally {
            setIsLoading(false)
        }
    }, [email, code])

    const handleResetPassword = useCallback(async () => {
        // Validation
        if (!password) {
            setErrors({...errors, password: 'Please enter a password.'})
            return
        }
        if (password.length < 8) {
            setErrors({
                ...errors,
                password: 'Password must be at least 8 characters long.',
            })
            return
        }
        if (password !== confirmPassword) {
            setErrors({...errors, confirmPassword: 'Passwords do not match.'})
            return
        }

        try {
            setIsLoading(true)
            const response = await put(
                `${process.env.REACT_APP_API_AUTH}/api/password-reset`,
                {
                    email: email,
                    code: code,
                    password: password,
                    password_confirmation: confirmPassword,
                },
            )

            toast.success('Password reset successfully')
            onBack && onBack()

        } catch (error) {
            console.error('Error Resend code verify email', error)
        } finally {
            setIsLoading(false)
        }
    }, [email, code, confirmPassword, password])

    const onSubmit = useCallback(
        async (e) => {
            e.preventDefault()

            if (StepForgotPassword.VERIFY_EMAIL === stepForgotPassword) {
                await handleResetPasswordVerifyEmail()
            } else if (StepForgotPassword.VERIFY_CODE === stepForgotPassword) {
                await handleVerifyCodeReset()
            } else {
                await handleResetPassword()
            }
        },
        [
            stepForgotPassword,
            handleResetPassword,
            handleResetPasswordVerifyEmail,
            handleVerifyCodeReset,
        ],
    )
    return (
        <div>
            <p className={'text-3xl font-semibold text-white'}>
                Forgot Password{' '}
            </p>
            <p className={'text-sm text-white'}>
                No worries, we'll send you rest instructions
            </p>

            <form onSubmit={onSubmit}>
                {stepForgotPassword === StepForgotPassword.VERIFY_EMAIL ? (
                    <Input
                        value={email}
                        placeholder="Enter email"
                        className="mt-4 w-full main border bg-[#0d0e12] text-white rounded-md"
                        onChange={(e) => setEmail(e.target.value)}
                        error={errors.email}
                    />
                ) : stepForgotPassword === StepForgotPassword.VERIFY_CODE ? (
                    <Input
                        value={code}
                        placeholder="Enter code"
                        className="mt-4 w-full main border bg-[#0d0e12] text-white rounded-md"
                        onChange={(e) => setCode(e.target.value)}
                        error={errors.code}
                    />
                ) : (
                    <>
                        <Input
                            value={password}
                            placeholder="Enter password"
                            type={'password'}
                            className="mt-4 w-full main border bg-[#0d0e12] text-white rounded-md"
                            onChange={(e) => setPassword(e.target.value)}
                            error={errors.password}
                        />
                        <Input
                            type={'password'}
                            value={confirmPassword}
                            placeholder="Enter confirm password"
                            className="mt-4 w-full main border bg-[#0d0e12] text-white rounded-md"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            error={errors.confirmPassword}
                        />
                    </>
                )}

                <Button loading={isLoading}  variant={"main"} type={'submit'} className={'w-full mt-6'}>
                    { !isLoading && 'Confirm' }
                </Button>
            </form>
        </div>
    )
}

export default StepForgotPasswordComponent
