import { Wallet } from '@mui/icons-material'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import copy from 'copy-to-clipboard'
import { CheckCircle, Copy, User} from 'lucide-react'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useAuth } from 'src/lib/context/AuthContext'
import { cn } from 'src/utils/common'
import { useAccount } from 'wagmi'
import { put } from '../../../api/axiosClient'
import { Button } from 'src/components/ui/button'
import {setDataToCookie} from "../../../lib/cookie/client";
const avatarList = ["https://i.seadn.io/gcs/files/8be48e2e53d43a191378bd322ea93e4e.png?auto=format&dpr=1&w=750", "https://i.seadn.io/gcs/files/b93966fec521da2ac80f90ee441e0fd5.png?auto=format&dpr=1&w=750", "https://i.seadn.io/s/raw/files/cabe9e118afc15d12898ff15777e085a.png?auto=format&dpr=1&w=1000"]

const SettingProfile = () => {
    const { currentUser, setCurrentUser } = useAuth()
    const { nick_name, connect_wallet_address, avatar } =
        currentUser?.user ?? {}
    const [username, setUserName] = useState(nick_name)
    const [address, setAddress] = useState(connect_wallet_address)
    const [img, setImg] = useState(avatar)
    const { open: openModalConnectWallet } = useWeb3Modal()
    const { address: walletAddress } = useAccount()
    const [isLoading, setIsLoading] = useState(false)



    useEffect(() => {
        if(img) return
        setImg(avatar)
    }, [avatar]);

    useEffect(() => {
        setUserName(nick_name)
    }, [nick_name])
    useEffect(() => {
        if (!connect_wallet_address) {
            setAddress(walletAddress ?? '')
        } else {
            setAddress(connect_wallet_address)
        }
    }, [connect_wallet_address, walletAddress])

    const copyToClipboardWithCommand = () => {
        copy(connect_wallet_address as string, { format: 'text/plain' })
        toast.success('Copied')
    }

    const handleSaveUserInformation = async (data) => {
        if (!currentUser) return
        setIsLoading(true)
        try {
            const response = await put(
                `${process.env.REACT_APP_API_AUTH}/api/update-user-profile`,
                data,
            ).then((res) => res)
            if (response) {
                const newData = {
                ...currentUser,
                    user: {
                    ...currentUser?.user,
                    ...response?.user,
                }
                }
                setCurrentUser(newData)
                setDataToCookie("user", newData)
            }
            toast.success('Update wallet address successfully')
        } catch (error) {
            console.error('Error updating user profile:', error)
        }finally {
            setIsLoading(false)
        }
    }

    return (
        <div className="w-full pb-10">
            <div className="pt-5 pb-[52px] px-4 sm:px-6 md:px-14 rounded-[20px] bg-[#10161F] max-w-[719px] w-full">
                <div className="flex items-center">
                    <User className="text-[#7E9BB2] w-6 h-6" />
                    <p className="pl-1 text-2xl font-bold text-white">
                        Profile Settings
                    </p>
                </div>
                <div className="mt-5">
                    <p className="text-white text-xl pb-2 font-normal">
                        Username
                    </p>
                    <input
                        onChange={(e) => setUserName(e?.target?.value)}
                        value={username}
                        className="bg-[#16202D] border-none w-full rounded-[10px] h-[50px] text-white"
                        placeholder="Username"
                    />
                </div>
                <div className={"mt-5"}>
                    <p className="text-white text-xl pb-2 font-normal">
                        Avatar
                    </p>

                    <div className={"flex"}>
                        {
                            avatarList?.map((e, idx)=> {
                                return (
                                    <div
                                        onClick={() => setImg(e)}
                                        className={cn("border-[3px] relative items-center justify-center hover:border-sky-500 rounded-full ml-2 cursor-pointer border-[#737d8f]", img?.toLowerCase() === e?.toLowerCase()  && "border-sky-500")}>
                                        <img className={"w-[48px] h-[48px] rounded-full"} alt={`avatar_${idx}`} src={e} />
                                    </div>
                                )
                            })
                        }

                    </div>


                </div>

                <Button
                    onClick={() =>
                        handleSaveUserInformation({
                            nick_name: username,
                            avatar: img
                        })
                    }
                    loading={isLoading}
                    disabled={(username?.trim() === "" || username?.toLowerCase() === nick_name?.toLowerCase() ) && avatar?.toLowerCase() === img?.toLowerCase()}
                    className="mt-12 text-white button-save-setting w-full rounded-[10px] h-[51px] text-xl font-bold"
                >
                    Save Change
                </Button>
            </div>

            <div className="pt-5 pb-[52px] px-4 sm:px-6 md:px-14 mt-12 rounded-[20px] bg-[#10161F] max-w-[719px] w-full">
                <div className="flex items-center">
                    <Wallet className="text-[#7E9BB2] w-6 h-6" />
                    <p className="pl-1 text-2xl font-bold text-white">
                        Connect Wallet
                    </p>
                </div>

                <div className="mt-5">
                   <div className={"w-full mb-2 flex items-center justify-between"}>
                       <p className="text-white text-xl font-normal">
                           Wallet Address
                       </p>
                       <div className={"px-3 flex rounded py-2 border border-slate-600"}>

                           <p className={"text-slate-200 text-sm font-medium"}>
                               ARBITRUM
                           </p>
                           <img src={"https://s2.coinmarketcap.com/static/img/coins/64x64/11841.png"} alt={"arb"} className={"w-5 ml-2 h-5 rounded-full"}/>
                       </div>
                   </div>
                    <div
                        className={cn(
                            ' flex items-center px-3  relative bg-[#16202D] rounded-[10px]',
                            !connect_wallet_address && 'pl-0',
                        )}
                    >
                        {connect_wallet_address ? (
                            <Copy
                                onClick={copyToClipboardWithCommand}
                                className="w-6 h-5 absolute text-[#416697] cursor-pointer"
                            />
                        ) : null}

                        <input
                            className={cn(
                                'bg-[#16202D]  border-none rounded-[10px] w-full h-[50px] text-white',
                                connect_wallet_address && 'mx-6',
                            )}
                            placeholder="Wallet address"
                            value={address}
                            disabled={!!connect_wallet_address}
                            onChange={(e) => setAddress(e.target.value)}
                        />

                        {connect_wallet_address ? (
                            <div className="w-6 h-6">
                                <CheckCircle className="w-6 h-6  text-[#42DC6E]" />
                            </div>
                        ) : null}
                    </div>
                </div>

                {!connect_wallet_address ? (
                    <Button
                        onClick={() =>
                            !connect_wallet_address && !walletAddress
                                ? openModalConnectWallet()
                                : handleSaveUserInformation({
                                      connect_wallet_address: address,
                                  })
                        }
                        loading={isLoading}
                        className="mt-12 text-white rounded-[10px] w-full button-save-setting h-[51px] text-xl font-bold"
                    >
                        {!connect_wallet_address && !walletAddress
                            ? 'Connect wallet'
                            : 'Save wallet address'}
                    </Button>
                ) : null}
            </div>
        </div>
    )
}

export default SettingProfile
