import { Info } from 'lucide-react'
import { formatNumberWithNumeral } from 'src/lib/wagmi/utils/formatNumber'
import ethLogo from '../../../assets/images/web/eth.svg'
import {BgNft, Rate, RateBg, borderCard, Class} from '../configs'
import { NFTDetails, NftListingResponse } from '../interfaces'
import {useGetPriceEth} from "../../../lib/hooks/usePriceEth";
import TooltipShad from "../../../components/ui/ShadTooltip";

const CardNft = ({
    item,
    dataOwner,
}: {
    item?: NftListingResponse
    dataOwner?: NFTDetails
}) => {
    const priceEth = useGetPriceEth()
    const data = item
        ? item
        : {
              nft: {
                  ...dataOwner,
              },
              price: 0,
          }

    const listIcon = [
        {
            icon: require('../../../assets/images/web/Icon140.png'),
            name: 'Movespeed',
            value: data?.nft.move_speed ?? 0,
        },
        {
            icon: require('../../../assets/images/web/Icon141.png'),
            name: 'Order speed',
            value: data?.nft?.order_speed ?? 0,
        },
        {
            icon: require('../../../assets/images/web/Icon142.png'),
            name: 'Working speed',
            value: data?.nft?.working_speed ?? 0,
        },
        {
            icon: require('../../../assets/images/web/Icon143.png'),
            name: 'Income',
            value: data?.nft?.income ?? 0,
        },
        {
            icon: require('../../../assets/images/web/Icon144.png'),
            name: 'Lucky',
            value: `${data?.nft?.lucky?.toFixed(2) ?? 0}%`,
        },
    ]


    return (
        <a
            href={`/marketplace/${data?.nft?.market_type ?? 'staff'}/${
                data?.nft?.nft_id
            }`}
        >
            <div
                className=" rounded-[20px]
    bg-[length:100%_100%]
    bg-no-repeat
    bg-center
    max-w-[300px]
    h-[300px]
    min-w-[245px]
    border
"
                style={{
                    background: BgNft[data?.nft?.rare || 0],
                    borderColor: borderCard[data?.nft?.rare || 0],
                }}
            >
                <div className="flex flex-row justify-between px-4 pt-3">
                    <div
                        className=" rounded-[5px]
            px-2
            text-[14px]
            flex
            items-center
            justify-center
            h-[24px]
            max-w-[98px]
            w-full
            text-white
        "
                        style={{
                            background: RateBg[data?.nft?.rare ?? 'common'],
                        }}
                    >
                        {Rate[data?.nft.rare || 0]}
                    </div>
                    <div
                        className="
            bg-[#111111]
            text-[14px]
            text-[#AFAFAF]
            flex
            px-2
            items-center
            justify-center
            h-[24px]
            rounded-[5px]
        "
                    >
                        #{data?.nft.nft_id}
                    </div>
                </div>
                <div className="flex flex-col justify-center sm:h-[70%] h-[70%] items-center">
                    <img
                        src={data?.nft?.image}
                        alt={data?.nft?.name}
                        className="w-[140px] -mt-6"
                    />
                    {!!(data as any)?.price && (
                        <div className="flex flex-col -mt-6  w-full text-center">
                            <div className="text-[20px] text-white font-bold">
                                <img
                                    alt="dollar"
                                    src={ethLogo}
                                    className="w-5 h-5 inline-block mr-1"
                                />
                                {formatNumberWithNumeral(data?.price)}
                            </div>
                            <div className="text-[14px] font-bold text-white">
                                ${formatNumberWithNumeral(data?.price * priceEth)}
                            </div>
                        </div>
                    )}
                </div>
                <div
                    className="
        w-full
        flex
        flex-col
        flex-wap
        bg-[#0F161F]
        px-4
        rounded-b-[20px]
        h-[51px]
    "
                >
                    <div className="w-full h-full text-[#A7AFBE] flex flex-wrap justify-between">
                        <div className="text-[14px] flex items-center">
                            {data?.nft?.name}
                        </div>
                        <div className="flex items-center">
                            <TooltipShad
                            trigger={<Info className="text-[#3B4252] w-[24px] h-[24px]" />}
                            classContent={"max-w-[340px] w-full"}
                            content={<div>
                                <p className={"text-lg font-medium text-[#FF9345]"}>
                                    Attributes
                                </p>
                                <div className={"flex gap-y-2 flex-wrap mb-2"}>
                                    <div className={"flex w-1/2 items-center"}>
                                        <p className={"main-text-500 mr-2 font-medium"}>
                                           Class:
                                        </p>
                                        <div
                                            className=" px-2 text-slate-200 text-[14px] flex w-fit items-center justify-center h-[24px] rounded-[5px]"
                                            style={{
                                                background:
                                                    borderCard[
                                                        Number(data.nft?.class)
                                                        ],
                                            }}
                                        >
                                            {Class[ Number(data.nft?.class)]}
                                        </div>
                                    </div>
                                    <div className={"w-1/2 flex items-center"}>
                                        <p className={"main-text-500 mr-2 font-medium"}>
                                            Rate:
                                        </p>
                                        <div
                                            className=" px-2 text-slate-200 text-[14px] flex w-fit items-center justify-center h-[24px] rounded-[5px]"
                                            style={{
                                                background:
                                                    RateBg[
                                                        Number(data.nft?.rare)
                                                        ],
                                            }}
                                        >
                                            {Rate[ Number(data.nft?.rare)]}
                                        </div>
                                    </div>
                             <div className={"w-1/2 flex items-center"}>
                                 <p className={"main-text-500 mr-2 font-medium"}>
                                     Level:
                                 </p>
                                 <div
                                     className="
                              bg-[#BC7330]
                              text-[14px]
                              min-w-[48px]
                              flex
                              w-fit
                              px-2
                              items-center
                              justify-center
                              h-[24px]
                              rounded-[5px]
                              text-slate-200
                          "
                                 >
                                     {data?.nft?.level}
                                 </div>
                             </div>
                                </div>
                                <p className={"border-t text-lg font-medium border-slate-500 mt-3 pt-3 text-[#FF9345]"}>
                                    Traits
                                </p>
                                <div className={"flex flex-wrap gap-y-2"}>
                                    {listIcon?.map((e, idx) => {
                                        return(
                                            <div key={`item-tooltip-${idx}`} className={"flex items-center w-[50%]"}>
                                                <img src={e?.icon} alt={"icon"} className={"w-8 h-8"}/>
                                                <div className={"pl-2"}>
                                                    <p className={"font-medium text-base text-slate-500"}>
                                                        {e?.name}
                                                    </p>
                                                    <p className={"font-medium text-sm text-slate-200"}>
                                                        {e?.value}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </a>
    )
}

export default CardNft
