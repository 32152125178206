import { fetcher } from 'src/utils/fetcher'
import useSWR from 'swr'
import { BuyHistoryEntity } from '../../interfaces'
import { useParams } from 'react-router-dom'
import { truncateAddress } from 'src/lib/wagmi/utils/truncateAddress'
import { formatNumberWithNumeral } from 'src/lib/wagmi/utils/formatNumber'
import ethLogo from '../../../../assets/images/web/eth.svg'
import dayjs from 'dayjs'
import EmptyState from 'src/components/Common/EmptyState'

const BuyHistory = () => {
    const params = useParams()

    const { data: buyHistory } = useSWR(
        `${process.env.REACT_APP_API}/v1/marketplace/buy-history/${params?.id}`,
        fetcher,
    )

    const listBuyHistory = (buyHistory?.data ?? []) as BuyHistoryEntity[]

    return (
        <div className="w-full bg-[#0D1219] md:w-[60%] flex flex-col rounded-[20px]">
            <div className="w-full h-[76px] bg-[#10161F] rounded-t-[20px] flex items-center text-2xl text-[#FF9345] font-bold p-5">
                Sale History
            </div>
            <div className="table-wrapper">
                <table className="w-full text-[14px] table-auto table-market">
                    <thead className="text-left h-[36px]">
                        <tr>
                            <th className="px-8">BUYER</th>
                            <th className="px-8">SELLER</th>
                            <th className="px-8">DATE/PRICE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listBuyHistory && listBuyHistory?.length > 0 ? (
                            listBuyHistory?.map((item, index) => (
                                <tr key={`item-buy-history-${index}`}>
                                    <td>
                                        <div className="flex flex-row gap-3 items-center py-2 px-5">
                                            <div className="w-10 h-10">
                                                <img
                                                    src={item?.buyer_avatar}
                                                    className="w-10 h-10 rounded-full"
                                                    alt="logo-buyer"
                                                />
                                            </div>
                                            <div className="flex flex-col gap-1">
                                                <div className="text-[14px] font-bold">
                                                    {item?.buyer_name}
                                                </div>
                                                <div className="text-[12px]">
                                                    (
                                                    {truncateAddress(
                                                        item?.buyer_address,
                                                    )}
                                                    )
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex flex-row gap-3 items-center py-2 px-5">
                                            <div className="w-10 h-10">
                                                <img
                                                    alt="logo-seller"
                                                    src={item?.seller_avatar}
                                                    className="w-10 h-10 rounded-full"
                                                />
                                            </div>
                                            <div className="flex flex-col gap-1">
                                                <div className="text-[14px] font-bold">
                                                    {item?.seller_name}
                                                </div>
                                                <div className="text-[12px]">
                                                    (
                                                    {truncateAddress(
                                                        item?.buyer_address,
                                                    )}
                                                    )
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex flex-col gap-1 items-start py-2 px-5">
                                            <div className="flex flex-row gap-2 items-center">
                                                <div>
                                                    <img
                                                        src={ethLogo}
                                                        className="w-[16px] h-[16px]"
                                                        alt="currency"
                                                    />
                                                </div>
                                                <div className="font-bold">
                                                    {formatNumberWithNumeral(
                                                        item?.price,
                                                    )}
                                                </div>
                                            </div>
                                            <div className="text-[12px] text-[#506683]">
                                                {dayjs(item?.buy_time).format(
                                                    'MMM D, YYYY',
                                                )}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td></td>
                                <td>
                                    <EmptyState />
                                </td>
                                <td></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default BuyHistory
