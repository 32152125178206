/* eslint-disable @typescript-eslint/no-unused-vars */
const contractAddresses: {
    [key: string]: {
        [key: string]: string
    }
} = {
    marketplace: {
        production: '0x97d8301cc5e29608a489A362f3E5394B8149f360',
        development: '0x97d8301cc5e29608a489A362f3E5394B8149f360',
    },
    nft: {
        production: '0xe79FF652c5D9A06d2740A79a564B300F096402C3',
        development: '0xe79FF652c5D9A06d2740A79a564B300F096402C3',
    },
    arb: {
        production: '0x912CE59144191C1204E64559FE8253a0e49E6548',
        development: '0x912CE59144191C1204E64559FE8253a0e49E6548',
    },
}

export const getContractByName = (contractName: string) => {
    return contractAddresses[contractName][process.env.APP_ENV || 'development']
}
