export const explorerUrlPerChainId = {
  421614: 'https://sepolia.arbiscan.io',
  42161: 'https://arbiscan.io',
  5: 'https://goerli.etherscan.io'
};

export function getExplorerLink(
  chainId: number,
  urlHash: string | number,
  type: 'transaction' | 'token' | 'address' | 'countdown' | 'block'
): string {
  // @ts-ignore
  const prefix = explorerUrlPerChainId[chainId];

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${urlHash}`;
    }
    case 'token': {
      return `${prefix}/token/${urlHash}`;
    }
    case 'countdown': {
      return `${prefix}/block/countdown/${urlHash}`;
    }
    case 'block': {
      return `${prefix}/block/${urlHash}`;
    }
    case 'address':
    default: {
      return `${prefix}/address/${urlHash}`;
    }
  }
}
