import Lottie from 'lottie-react'
import ComingSoonJson from '../../lib/contants/animations/ComingSoon.json'

const ComingSoon = () => {
    return (
        <div className="w-fit mx-auto pt-20 px-2 pb-10">
            <div className="w-[200px] mx-auto">
                <Lottie
                    animationData={ComingSoonJson}
                    height={100}
                    loop={true}
                />
            </div>
            <p className="text-white text-xl font-semibold text-center pt-10">
                Coming Soon
            </p>
            <p className="text-[#899DCD] text-center">
                Exciting new feature on the horizon! Stay tuned for updates.
            </p>
        </div>
    )
}

export default ComingSoon
