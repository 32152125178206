import {Button} from "../../../components/ui/button";
import {StepModalLogin} from "../interfaces";

const StepInfo = ({handleNextStep}: { handleNextStep: (val: StepModalLogin) => void }) => {
    return (
        <div>
            <img
                src="/static/media/logo-header.0d3dbdd80c26d4f75031.png"
                alt="logo"
                className="w-[121px]"
            />
            <p className=" text-3xl mt-20 mb-3 font-medium text-white">
                Welcome back, Lunacian!
            </p>
            <p className="text-base mb-10 font-normal text-[#5C789C]">
                Select your login method
            </p>

            <Button
                className="main-button-primary !w-full"
                onClick={() => handleNextStep(StepModalLogin.SIGN_IN)}
            >
                Login with email or password
            </Button>
            <div className="mt-5">
                <p className="text-[#5C789C] text-sm font-normal">
                    By continuing, you are acknowledging and accepting our
                </p>
            </div>
            <p className="text-[#5C789C] my-12 text-sm text-center">
                Don't have an account?
                <span
                    onClick={() => handleNextStep(StepModalLogin.SIGN_UP)}
                    className="text-[#F58E3F] cursor-pointer font-semibold">
                    {' '}
                    Register Now{' '}
                </span>
            </p>
        </div>
    )
}

export default StepInfo