import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import landingPageApi from '../../../api/landingPageApi'
import {post} from "../../../api/axiosClient";
import {AniBarPost} from "../interfaces/news";
import {Loading} from "../../../components/ui/Loading";
import EmptyState from "../../../components/Common/EmptyState";
import {useRandomImage} from "./NewsPage";

const newBgImages = [
    require('../../../assets/images/web/news_bg_1.png'),
    require('../../../assets/images/web/news_bg_2.png'),
    require('../../../assets/images/web/news_bg_3.png')
];

type Props = {}

const NewDetailPage = (props: Props) => {
    const { id } = useParams()
    const [newDetail, setNewDetail] = useState<AniBarPost | null>(null)
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        const handleGetNewsDetailById =  async ()=>{
            setIsLoading(true)
            const response =     await post(`${process.env.REACT_APP_API_NEWS}/detail`, {
                id: id
            }, {}, false).then((res) => res).finally(() => setIsLoading(false))
            if(response){
                setNewDetail(response)
            }
        }

        handleGetNewsDetailById()

    },[])
    // get new detail from api

    // convert string to html
    const createMarkup = (html: string) => {
        return { __html: html }
    }

    const getImage = useRandomImage()
    const imgRadom = getImage()

    return (
        <div className='text-white'>
            {isLoading && !newDetail  ? <Loading/> : newDetail ?  (
                <>
                    <div className='w-full mt-[10px] mb-[30px]'>
                        <div 
                            className="
                                w-full
                                bg-no-repeat
                                relative
                                bg-cover
                                min-h-[300px]
                                flex
                                flex-row
                                rounded-[20px]
                            "
                            style={{
                                backgroundImage: `url(${newBgImages[newDetail.id % 3]})`,
                            }}
                        >
                            <div className=" inset-0 bg-black bg-opacity-50  sm:flex items-center p-5">
                                <div className='w-full'>
                                    <h3 className='font-bold text-[24px] sm:text-[40px]'>{newDetail.name}</h3>
                                    <p className='sm:text-[20px] text-[14px] white-space text-[#C2CBD2] font-medium'>{newDetail.description}</p>
                                </div>
                                <div
                                    className='
                                    w-1/3
                                    flex
                                    items-center
                                    justify-center
                                '
                                >
                                    <img
                                        className='
                                        w-full
                                        max-w-[216px]
                                        max-h-[216px]
                                        mt-3
                                        sm:mt-0
                                        object-contain
                                    '
                                        src={imgRadom}
                                    />
                                </div>
                            </div>


                        </div>

                    </div>
                    <div 
                        className='
                            w-full 
                            p-5
                            flex 
                            flex-col 
                            bg-[#10161F]
                            rounded-[20px]
                            border
                            border-[#263750]
                            border-solid
                        '
                    >
                        <div 
                            className='
                                w-full 
                                mx-auto 
                                flex 
                                flex-wrap 
                                justify-center 
                                sm:text-[20px] 
                                text-[14px]
                                font-medium 
                                text-[#C2CBD2]
                                whitespace-break-spaces
                            '
                        >
                            {newDetail.content && (
                                <div
                                    className='w-full'
                                    dangerouslySetInnerHTML={createMarkup(newDetail.content)}
                                />
                            )}
                        </div>
                    </div>
                </>
            ): <EmptyState/>}
        </div>
    )
}

export default NewDetailPage