import {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react'
import {deleteCookie, getDataFromCookie, setDataToCookie} from "../cookie/client";
import {get, post} from "../../api/axiosClient";
import defaultAvatar from "../../../src/assets/images/amimal.svg"
import {useDisconnect} from "wagmi";
import {useNavigate} from "react-router-dom";

interface IUser {
    id?: number;
    nick_name?: string;
    connect_wallet_address?: string;
    avatar?: string;
    snack_balance?: number,
}

export interface ICurrentUser {
    token: string,
    token_type: string
    expires_in: string,
    user: IUser

}

type AuthContextType = {
    currentUser: ICurrentUser | null
    setCurrentUser: (user: ICurrentUser | null) => void
    login: (email: string, password: string, onRememberMe?: () => void) => void
    logout: () => void
    isLoading: boolean
    loadingLogin: boolean
}

const AuthContext = createContext<AuthContextType | null>(null)

export const useAuth = () => {
    const context = useContext(AuthContext)

    if (!context)
        throw new Error('useAuth must be used within an AuthContextProvider')

    return context || ({} as AuthContextType)
}

export const AuthProvider = ({children}: { children: ReactNode }) => {
    const [user, setUser] = useState<ICurrentUser | null>(null)
    const [loading, setLoading] = useState(true)
    const [loadingLogin, setLoadingLogin] = useState(false)
    const userCookie = getDataFromCookie("user")
    const { disconnect} = useDisconnect()
    const navigate = useNavigate()

    useEffect(() => {
        if (userCookie) {
            setUser(userCookie as ICurrentUser)
        }
        setLoading(false)

    }, [])

    useEffect(() => {
        if (JSON.stringify(user) !== JSON.stringify(userCookie)) {
            // setDataToCookie("user", user)
            setLoading(false)
        }
    }, [user, userCookie])

    useEffect(() => {
        if(!user?.token) return
        const handleGetUserInfo = async ()  => {
            await get(`${process.env.REACT_APP_API_AUTH}/api/me`).then((res) => {
                setUser({
                    ...user as ICurrentUser,
                    user: {
                        ...user?.user,
                        snack_balance: res?.data?.snack_balance
                    }
                })
            })
        }

        handleGetUserInfo()
    },[user?.token])

    const login = async (email: string, password: string, onRememberMe?: () => void) => {
        setLoadingLogin(true)

        try {
            const response = await post(`${process.env.REACT_APP_API_AUTH}/api/login`, {
                email: email,
                password: password
            }).then((res) => res)

            if (response) {


                setUser(response)
                setDataToCookie("user", response)
                console.log("!response?.connect_wallet_address",response,  !response?.connect_wallet_address)
                if(!response?.user?.connect_wallet_address ) {
                    navigate('/profile/setting')
                }
                onRememberMe && onRememberMe()
            }
        } catch (error: any) {
            console.error('Error login email password', error)
        } finally {
            setLoadingLogin(false)
        }
    }

    const logout = () => {
        deleteCookie("user")
        setUser(null)
        disconnect()
    }

    return (
        <AuthContext.Provider
            value={{
                currentUser: user ? {
                    ...(user as ICurrentUser),
                    user: {
                        ...(user?.user as IUser),
                        avatar: user?.user?.avatar || defaultAvatar,
                    }
                } : null,
                login,
                logout,
                setCurrentUser: setUser,
                isLoading: loading,
                loadingLogin,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
