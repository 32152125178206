import React, { useState, Fragment } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Stepper, Step, Typography } from '@material-tailwind/react'

type Props = {
    sidebarOpen: boolean
    setSidebarOpen: (sidebarOpen: boolean) => void
}

const Search = ({ sidebarOpen, setSidebarOpen }: Props) => {
    const [activeStep, setActiveStep] = useState(3)
    const [activeStep2, setActiveStep2] = useState(3)

    return (
        <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-50"
                onClose={() => setSidebarOpen(false)}
            >
                <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-900/80" />
                </Transition.Child>
                <div className="fixed inset-0 flex">
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                    >
                        <Dialog.Panel className="absolute right-0 h-screen flex w-full sm:max-w-md flex-1">
                            <div className="flex grow text-white flex-col gap-y-5 justify-center overflow-y-auto bg-[#070A0E] px-8 pb-4 ring-1 ring-white/10">
                                <div className="flex flex-col">
                                    <h1 className="text-2xl font-bold text-center">
                                        Filter
                                    </h1>
                                    <div className="mt-2 flex flex-col">
                                        <label className="mb-2">Genre</label>
                                        <nav
                                            className="flex flex-wrap gap-3"
                                            aria-label="Tabs"
                                        >
                                            <a
                                                href="#"
                                                className="shrink-0 bg-[#16202D] rounded-lg px-5 py-2 text-sm font-medium hover:bg-gray-50 hover:text-gray-700"
                                            >
                                                Cat
                                            </a>
                                            <a
                                                href="#"
                                                className="shrink-0 bg-[#16202D] rounded-lg px-5 py-2 text-sm font-medium hover:bg-gray-50 hover:text-gray-700"
                                            >
                                                Dragon
                                            </a>
                                            <a
                                                href="#"
                                                className="shrink-0 bg-[#16202D] rounded-lg px-5 py-2 text-sm font-medium hover:bg-gray-50 hover:text-gray-700"
                                            >
                                                Bear
                                            </a>
                                            <a
                                                href="#"
                                                className="shrink-0 bg-[#16202D] rounded-lg px-5 py-2 text-sm font-medium hover:bg-gray-50 hover:text-gray-700"
                                            >
                                                Dog
                                            </a>
                                            <a
                                                href="#"
                                                className="shrink-0 bg-[#16202D] rounded-lg px-5 py-2 text-sm font-medium hover:bg-gray-50 hover:text-gray-700"
                                            >
                                                Rabbit
                                            </a>
                                        </nav>
                                    </div>
                                    <div className="w-full mt-5 flex flex-col">
                                        <label className="mb-2">Rarity</label>
                                        <Stepper
                                            activeStep={activeStep}
                                            lineClassName="bg-[#1C1F25] h-2"
                                            activeLineClassName="bg-[#F58E3F]"
                                            placeholder={undefined}
                                            onPointerEnterCapture={null}
                                            onPointerLeaveCapture={null}
                                        >
                                            {[...Array(6)].map((_, index) => (
                                                <Step
                                                    placeholder={undefined}
                                                    className="h-6 w-6 bg-[#1C1F25] cursor-pointer"
                                                    activeClassName="ring-0 bg-[#F58E3F]"
                                                    completedClassName="bg-[#F58E3F]"
                                                    onClick={() =>
                                                        setActiveStep(index)
                                                    }
                                                    onPointerEnterCapture={null}
                                                    onPointerLeaveCapture={null}
                                                ></Step>
                                            ))}
                                        </Stepper>
                                    </div>
                                    <div className="w-full mt-5 flex flex-col">
                                        <label className="mb-2">Level</label>
                                        <Stepper
                                            activeStep={activeStep2}
                                            lineClassName="bg-[#1C1F25] h-2"
                                            activeLineClassName="bg-[#F58E3F]"
                                            placeholder={undefined}
                                            onPointerEnterCapture={null}
                                            onPointerLeaveCapture={null}
                                        >
                                            {[...Array(5)].map((_, index) => (
                                                <Step
                                                    placeholder={undefined}
                                                    className="h-6 w-6 bg-[#1C1F25] cursor-pointer"
                                                    activeClassName="ring-0 bg-[#F58E3F]"
                                                    completedClassName="bg-[#F58E3F]"
                                                    onClick={() =>
                                                        setActiveStep2(index)
                                                    }
                                                    onPointerEnterCapture={null}
                                                    onPointerLeaveCapture={null}
                                                ></Step>
                                            ))}
                                        </Stepper>
                                    </div>
                                    <div className="w-full mt-5 flex flex-col">
                                        <label className="mb-2">
                                            Price (#ANI)
                                        </label>
                                        <div className="flex flex-col">
                                            <div className="flex gap-x-2 items-center">
                                                <input
                                                    type="text"
                                                    className="w-full rounded-lg bg-[#1A283A] text-white px-4 py-2"
                                                    value={'00.000'}
                                                    placeholder="00.000"
                                                />
                                                <span className="text-white text-[30px]">
                                                    -
                                                </span>
                                                <input
                                                    type="text"
                                                    className="w-full rounded-lg bg-[#1A283A] text-white px-4 py-2"
                                                    value={'00.000'}
                                                    placeholder="00.000"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full mt-8 flex flex-col">
                                        <button
                                            className="
                                            w-full 
                                            bg-[url('../src/assets/images/web/bg-bt-filter.png')]
                                            bg-[length:100%_100%]
                                            bg-no-repeat
                                            bg-center
                                            text-white 
                                            h-[50px]
                                            px-4 
                                            py-2
                                            text-[20px]
                                        "
                                        >
                                            RESET
                                        </button>
                                    </div>
                                </div>

                                <div className="sm:sr-only not-sr-only w-full mt-8 flex flex-col justify-center items-center">
                                    <button
                                        type="button"
                                        onClick={() => setSidebarOpen(false)}
                                    >
                                        <XMarkIcon
                                            className="h-10 w-10 text-white"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default Search
