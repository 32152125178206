import NFTCategory from '../components/NFTCategory'
import NFTItemStats from '../components/NFTItemStats'
import OverallStats from '../components/OverallStats'

const HomeMarketPlace = () => {
    return (
        <div className="text-white">
            <NFTCategory />
            <OverallStats />
            <NFTItemStats />
        </div>
    )
}

export default HomeMarketPlace
