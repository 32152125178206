import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './rootSaga'
import authReducer from '../features/auth/authSlice'
import appReducer from 'src/features/app/appSlice'

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
    reducer: {
        app: appReducer,
        auth: authReducer,
    },
    middleware: (getDefaultMiddleware: any) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(
            sagaMiddleware,
        ),
})

sagaMiddleware.run(rootSaga)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
